import React, { createContext, useState, useContext,useEffect } from 'react';
import Cookies from 'js-cookie';
// Create the context
const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(Cookies.get('authToken'));
  const [adminToken, setAdminToken] = useState(Cookies.get('AdTok'));
  useEffect(() => {
    const token = Cookies.get('authToken');
    if (token) {
      setAuthToken(token);
    }
  }, []);
  // Login function
  useEffect(() => {
    // Define an async function inside useEffect
    const fetchAdminToken = async () => {
      // Simulate an asynchronous operation if needed
      const token = Cookies.get('AdTok');
      if (token) {
        setAdminToken(token);
      }
    };

    fetchAdminToken(); // Call the async function
  }, []);

  // Logout function
  const updateUserToken = (token) => {
    Cookies.set('authToken', token, { expires: 7, secure: true, sameSite: 'Strict' }); // Store in cookies
    setAuthToken(token);
  };
  const updateAdminToken = (token) => {
    Cookies.set('AdTok', token);
    setAdminToken(token);
  };

  return (
    <AuthContext.Provider value={{ authToken ,adminToken,updateAdminToken,updateUserToken}}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the Auth context
export const useAuth = () => useContext(AuthContext);
