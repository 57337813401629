import React, { useState,useEffect } from "react";
import Paypal from "../Paymentmethods/Paypal";
import useVisaStore from "../../../../Stores/visaStoreCookie";

const Step7 = ({formik,setPaymentCompleted,price }) => {
 
  const [currency, setCurrency] = useState("USD");
  const { formData } = useVisaStore(); // Initialize currency state

  const onCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  useEffect(() => {
    if (formData.paymentDetails && formData.paymentDetails.status === "COMPLETED") {
      setPaymentCompleted(true); // Ensure payment completed is set to true
    }
  }, [formData, setPaymentCompleted]);

  const paymentCompleted = formData.paymentDetails && formData.paymentDetails.status === "COMPLETED";
  

  

  return (
    <>
      <div className="md:flex md:flex-row flex-col justify-between border-b border-gray-200">
        <h1 className="text-2xl font-bold pb-4 text-gray-600">
          Choose Your Payment Method
        </h1>
      </div>
      <div className="mb-4">
      <div className="flex flex-col">
        {/* PayPal Payment Option */}
        <label className="flex items-center cursor-pointer mb-2">
          <div
            className={`border-2 hover:border-2 hover:border-black text-center font-semibold rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
              formik.values.paymentMethod === "PayPal" ? "border-2 border-black" : ""
            }`}
          >
            PayPal
            <input
              type="radio"
              className="hidden"
              name="paymentMethod"
              value="PayPal"
              checked={formik.values.paymentMethod === "PayPal"}
              onChange={formik.handleChange}
              
            />
          </div>
        </label>

        {/* JazzCash Payment Option */}
        <label className="flex items-center cursor-pointer mb-2">
          <div
            className={`border-2 hover:border-2 hover:border-black text-center font-semibold rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
              formik.values.paymentMethod === "JazzCash" ? "border-2 border-black" : ""
            }`}
          >
            JazzCash
            <input
              type="radio"
              className="hidden"
              name="paymentMethod"
              value="JazzCash"
              checked={formik.values.paymentMethod === "JazzCash"}
              onChange={formik.handleChange}
              disabled={paymentCompleted}
            />
          </div>
        </label>

        {/* Bank Transfer Payment Option */}
        <label className="flex items-center cursor-pointer mb-2">
          <div
            className={`border-2 hover:border-2 hover:border-black text-center font-semibold rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
              formik.values.paymentMethod === "Bank" ? "border-2 border-black" : ""
            }`}
          >
            Bank Transfer
            <input
              type="radio"
              className="hidden"
              name="paymentMethod"
              value="Bank"
              checked={formik.values.paymentMethod === "Bank"}
              onChange={formik.handleChange}
              disabled={paymentCompleted}
            />
          </div>
        </label>
      </div>

      {/* Display validation error */}
      {formik.touched.paymentMethod && formik.errors.paymentMethod && (
        <p className="text-red-500 text-sm mt-1">
          {formik.errors.paymentMethod}
        </p>
      )}
    </div>


      
      {!paymentCompleted && formik.values.paymentMethod === 'PayPal' && (
        <Paypal currency={currency} onCurrencyChange={onCurrencyChange} onPaymentSuccess={setPaymentCompleted} price={price} />
      )}
      {paymentCompleted && (
        <div className="text-green-500 text-lg mt-4">
          Payment has already been completed successfully!
        </div>
      )}
    </>
  );
};

export default Step7;
