import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import useVisaStore from "../../Stores/visaStoreCookie";

const UncompleteApp = () => {
  const navigate = useNavigate();
  const { hasApplication, loading, fetchFormData,clearFormData } = useVisaStore(state => ({
    hasApplication: Object.keys(state.formData).length > 0, 
    loading: state.loading,
    fetchFormData: state.fetchFormData,
    clearFormData: state.clearFormData,
  }));

  useEffect(() => {
    const fetchData = async () => {
      await fetchFormData(); 
    };
    
    fetchData();
  }, [fetchFormData]);

  const handleNavigateToApplication = () => {
    navigate("/apply"); 
  };
  const handleDeleteApplication = async () => {
    await clearFormData(); // Call the clearFormData function
    // Optionally navigate to a different page or show a success message
    navigate("/"); // Navigate to home or another page after deletion
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {hasApplication && (
        <>
         
          <div
            className="flex items-center p-4 text-sm text-blue-800 rounded-lg bg-blue-50  shadow-lg"
            role="alert"
          >
            <svg
              className="flex-shrink-0 inline w-4 h-4 mr-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="font-medium">Info alert!</span> You have started an
            application! Click the button below to continue.
            <button
              onClick={handleNavigateToApplication}
              className="ml-4 bg-gradient-to-r from-[#25CBD6] to-[#00EE8A] rounded-lg py-2 px-4 font-semibold text-white"
            >
              Go to Your Application
            </button>
            <button
              onClick={handleDeleteApplication} // Call the delete function
              className="ml-4 bg-red-600 hover:bg-red-700 rounded-lg py-2 px-4 font-semibold text-white"
            >
              Delete My Application
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default UncompleteApp;
