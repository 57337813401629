import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAdminStore from "../../../Stores/adminStore";

const CreateCoupon = () => {
    const { createCoupon, fetchAdminData, isFetchingAdminData, isLoading, error } = useAdminStore();
    const [tokenfup, setTokenfup] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
          const adminData = await fetchAdminData();
          if (adminData) {
            setTokenfup(adminData.token);
            console.log(adminData);
          }
        };
        fetchUser();
      }, [fetchAdminData]);
      const formik = useFormik({
        initialValues: {
          discountPercentage: "", // Initial value for coupon discount
        },
        validationSchema: Yup.object({
          discountPercentage: Yup.number()
            .required("Discount percentage is required")
            .min(1, "Discount percentage must be at least 1")
            .max(100, "Discount percentage cannot exceed 100"),
        }),
        onSubmit: async (values) => {
          try {
            const res = await createCoupon(values.discountPercentage, tokenfup);
            if (res) {
              toast.success("Coupon created successfully!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
              });
              formik.resetForm(); // Clear form after successful submission
            } else {
              toast.error("Error creating coupon", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
              });
            }
          } catch (error) {
            console.error("Error creating coupon:", error);
          }
        },
      });
    
      useEffect(() => {
        if (error) {
          toast.error(`Error: ${error}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      }, [error]);
    
  return (
    <>
    {isFetchingAdminData ? (
      <div className="flex justify-center items-center min-h-screen">
        <div className="relative">
          <div className="w-20 h-20 border-purple-200 border-2 rounded-full"></div>
          <div className="w-20 h-20 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
        </div>
      </div>
    ) : (
      <>
        <ToastContainer />
        <form onSubmit={formik.handleSubmit}>
          <div className="py-6 px-4 sm:p-6 lg:pb-8">
            <div>
              <h2 className="text-lg font-medium leading-6 text-gray-900">Create New Coupon</h2>
              <p className="mt-1 text-sm text-gray-500">
                Fill in the details to create a new coupon.
              </p>
            </div>

            <div className="mt-6">
              {/* Discount Percentage */}
              <div className="mb-4">
                <label htmlFor="discountPercentage" className="block text-sm font-medium text-gray-700">Discount Percentage:</label>
                <input
                  type="number"
                  id="discountPercentage"
                  value={formik.values.discountPercentage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                    formik.touched.discountPercentage && formik.errors.discountPercentage
                      ? "border-red-500"
                      : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                  } sm:text-sm`}
                />
                {formik.touched.discountPercentage && formik.errors.discountPercentage && (
                  <div className="text-red-500 text-sm">{formik.errors.discountPercentage}</div>
                )}
              </div>
            </div>

            <div className="py-3 text-right">
              <button
                type="submit"
                disabled={isLoading}
                className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md"
              >
                {isLoading ? "Creating..." : "Create Coupon"}
              </button>
            </div>
          </div>
        </form>
      </>
    )}
  </>
  )
}

export default CreateCoupon