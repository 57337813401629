import React from 'react'

const Step1Det = ({selectedCountry,countryInfo,handleDelete}) => {
  return (
   <>
    <div className="md:w-80 bg-gray-100 border border-gray-50 p-6 rounded-lg shadow-md flex flex-col h-[350px]">
              <h2 className="text-xl font-semibold mb-4 border-b border-gray-300 pb-4">
                {selectedCountry?.name || "Select a country to see details"} visit
                visa
              </h2>
              {selectedCountry && (
                <div className="space-y-4">
                  {selectedCountry.visaTypes && (
                    <div className="flex items-center">
                      <svg
                        className="h-6 h-6 "
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                      >
                        <path d="M24.96 5.76001H23.68V4.48001C23.68 3.71201 23.168 3.20001 22.4 3.20001C21.632 3.20001 21.12 3.71201 21.12 4.48001V5.76001H10.88V4.48001C10.88 3.71201 10.368 3.20001 9.6 3.20001C8.832 3.20001 8.32 3.71201 8.32 4.48001V5.76001H7.04C4.864 5.76001 3.2 7.42401 3.2 9.60001V10.88H28.8V9.60001C28.8 7.42401 27.136 5.76001 24.96 5.76001ZM3.2 24.96C3.2 27.136 4.864 28.8 7.04 28.8H24.96C27.136 28.8 28.8 27.136 28.8 24.96V13.44H3.2V24.96ZM22.4 16C23.168 16 23.68 16.512 23.68 17.28C23.68 18.048 23.168 18.56 22.4 18.56C21.632 18.56 21.12 18.048 21.12 17.28C21.12 16.512 21.632 16 22.4 16ZM22.4 21.12C23.168 21.12 23.68 21.632 23.68 22.4C23.68 23.168 23.168 23.68 22.4 23.68C21.632 23.68 21.12 23.168 21.12 22.4C21.12 21.632 21.632 21.12 22.4 21.12ZM16 16C16.768 16 17.28 16.512 17.28 17.28C17.28 18.048 16.768 18.56 16 18.56C15.232 18.56 14.72 18.048 14.72 17.28C14.72 16.512 15.232 16 16 16ZM16 21.12C16.768 21.12 17.28 21.632 17.28 22.4C17.28 23.168 16.768 23.68 16 23.68C15.232 23.68 14.72 23.168 14.72 22.4C14.72 21.632 15.232 21.12 16 21.12ZM9.6 16C10.368 16 10.88 16.512 10.88 17.28C10.88 18.048 10.368 18.56 9.6 18.56C8.832 18.56 8.32 18.048 8.32 17.28C8.32 16.512 8.832 16 9.6 16ZM9.6 21.12C10.368 21.12 10.88 21.632 10.88 22.4C10.88 23.168 10.368 23.68 9.6 23.68C8.832 23.68 8.32 23.168 8.32 22.4C8.32 21.632 8.832 21.12 9.6 21.12Z"></path>
                      </svg>
                      <div className="flex-col pl-4">
                        <span className="font-medium">Visa Type:</span>{" "}
                        <div className="fi">{selectedCountry.visaTypes} </div>
                      </div>
                    </div>
                  )}
                  {selectedCountry.visaValidationPeriod && (
                    <div className="flex items-center">
                      <svg
                        className="h-6 h-6 "
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1.61351 11.0234L1.60001 6.3875C1.60001 5.90468 2.02751 5.54843 2.47751 5.65156L4.07951 6.02187C4.55651 6.12968 4.94351 6.48593 5.11451 6.95937L5.92001 9.2L11.6485 10.9625L9.78101 4.15625C9.65051 3.67812 9.99701 3.2 10.474 3.2H12.2785C12.8005 3.2 13.2775 3.49062 13.534 3.96406L18.439 13.0484L23.263 14.5344C23.9785 14.7547 24.649 15.1203 25.2295 15.6031L26.7775 16.8969C27.8575 17.7969 27.592 19.5828 26.296 20.0937C24.442 20.825 22.417 20.9422 20.5 20.4219L7.07651 16.7844C6.57701 16.6484 6.12251 16.3766 5.75801 15.9922L2.02751 12.0781C1.76201 11.7969 1.60901 11.4219 1.60901 11.0234H1.61351ZM3.04001 24.2H28.96C29.7565 24.2 30.4 24.8703 30.4 25.7C30.4 26.5297 29.7565 27.2 28.96 27.2H3.04001C2.24351 27.2 1.60001 26.5297 1.60001 25.7C1.60001 24.8703 2.24351 24.2 3.04001 24.2ZM7.36001 20.45C7.36001 20.0522 7.51172 19.6706 7.78177 19.3893C8.05182 19.108 8.41809 18.95 8.80001 18.95C9.18192 18.95 9.54819 19.108 9.81824 19.3893C10.0883 19.6706 10.24 20.0522 10.24 20.45C10.24 20.8478 10.0883 21.2294 9.81824 21.5107C9.54819 21.792 9.18192 21.95 8.80001 21.95C8.41809 21.95 8.05182 21.792 7.78177 21.5107C7.51172 21.2294 7.36001 20.8478 7.36001 20.45ZM13.12 19.7C13.5019 19.7 13.8682 19.858 14.1382 20.1393C14.4083 20.4206 14.56 20.8022 14.56 21.2C14.56 21.5978 14.4083 21.9794 14.1382 22.2607C13.8682 22.542 13.5019 22.7 13.12 22.7C12.7381 22.7 12.3718 22.542 12.1018 22.2607C11.8317 21.9794 11.68 21.5978 11.68 21.2C11.68 20.8022 11.8317 20.4206 12.1018 20.1393C12.3718 19.858 12.7381 19.7 13.12 19.7Z"></path>
                      </svg>
                      <div className="flex-col pl-4">
                        <span className="font-medium">Valid for:</span>{" "}
                        <div className="flex">{selectedCountry.visaValidationPeriod}</div>
                      </div>
                    </div>
                  )}
                  {countryInfo.entryNumber && (
                    <div className="flex items-center">
                      <svg
                        className="h-6 h-6 "
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M23.68 5.76H24.96C27.136 5.76 28.8 7.424 28.8 9.6V10.88H3.2V9.6C3.2 7.424 4.864 5.76 7.04 5.76H8.32V4.48C8.32 3.712 8.832 3.2 9.6 3.2C10.368 3.2 10.88 3.712 10.88 4.48V5.76H21.12V4.48C21.12 3.712 21.632 3.2 22.4 3.2C23.168 3.2 23.68 3.712 23.68 4.48V5.76ZM7.04 28.8C4.864 28.8 3.2 27.136 3.2 24.96V13.44H28.8V24.96C28.8 27.136 27.136 28.8 24.96 28.8H7.04ZM20.9183 17.4341C20.4606 17.0153 19.768 17.0153 19.3102 17.4341L14.4914 21.8435L12.6897 20.1949C12.232 19.7761 11.5394 19.7761 11.0817 20.1949C10.8538 20.4034 10.72 20.6854 10.72 20.9882C10.72 21.291 10.8538 21.573 11.0817 21.7816L13.6874 24.1659C13.9049 24.3649 14.1552 24.48 14.4914 24.48C14.8277 24.48 15.078 24.3649 15.2955 24.1659L20.9183 19.0208C21.1462 18.8122 21.28 18.5303 21.28 18.2274C21.28 17.9246 21.1462 17.6426 20.9183 17.4341Z"
                        ></path>
                      </svg>
                      <div className="flex-col pl-4">
                        <span className="font-medium">Number of entries:</span>

                        <div className="flex">{countryInfo.entryNumber}</div>
                      </div>
                    </div>
                  )}
                  {selectedCountry.maxStay && (
                    <div className="flex items-center">
                      <svg
                        className="h-6 h-6 "
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M23.68 5.76H24.96C27.136 5.76 28.8 7.424 28.8 9.6V10.88H3.2V9.6C3.2 7.424 4.864 5.76 7.04 5.76H8.32V4.48C8.32 3.712 8.832 3.2 9.6 3.2C10.368 3.2 10.88 3.712 10.88 4.48V5.76H21.12V4.48C21.12 3.712 21.632 3.2 22.4 3.2C23.168 3.2 23.68 3.712 23.68 4.48V5.76ZM7.04 28.8C4.864 28.8 3.2 27.136 3.2 24.96V13.44H28.8V24.96C28.8 27.136 27.136 28.8 24.96 28.8H7.04ZM20.9183 17.4341C20.4606 17.0153 19.768 17.0153 19.3102 17.4341L14.4914 21.8435L12.6897 20.1949C12.232 19.7761 11.5394 19.7761 11.0817 20.1949C10.8538 20.4034 10.72 20.6854 10.72 20.9882C10.72 21.291 10.8538 21.573 11.0817 21.7816L13.6874 24.1659C13.9049 24.3649 14.1552 24.48 14.4914 24.48C14.8277 24.48 15.078 24.3649 15.2955 24.1659L20.9183 19.0208C21.1462 18.8122 21.28 18.5303 21.28 18.2274C21.28 17.9246 21.1462 17.6426 20.9183 17.4341Z"
                        ></path>
                      </svg>
                      <div className="flex-col pl-4">
                        <span className="font-medium">Max stay:</span>
                        <div className="flex">{selectedCountry.maxStay}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="mt-4">
                <button
                  onClick={handleDelete}
                  className="w-full bg-gradient-to-r from-red-400 to-red-500 text-white font-medium py-3 rounded-lg shadow-md"
                >
                  Delete My application
                </button>
              </div>
            </div>
   </>
  )
}

export default Step1Det