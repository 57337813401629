import React,{useEffect} from 'react'
import Navbar from './Navbar/Navbar'
import HeroSection from './Landingpage/HeroSection'
import About from './Landingpage/About'
import Footer from './Footer/Footer'
import ScrolltoTop from './scrolltoTop/ScrolltoTop'
import HowitWorks from './Landingpage/HowitWorks'
import Team from './Landingpage/Team'
import Testimonial from './Landingpage/Testimonial'
import PopularTag from './Landingpage/PopularTag'
import FeatureComp from './Landingpage/FeatureComp'
import Services from './Landingpage/Services';
import UncompleteApp from './Uncomplete/UncompleteApp'



const Home = () => {
  useEffect(() => {
    // Function to scroll to the top
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
  
    // Call goToTop when the component mounts
    goToTop();
  }, []);
  
  return (
   <>
   <Navbar /> 
    <UncompleteApp />
   <HeroSection />
   <About />
   {/* <Services /> */}
   {/* <HowitWorks /> */}
   <FeatureComp />
   <Team />
   <Testimonial />
   <PopularTag />
   <Footer />
   <ScrolltoTop />
   </>
  )
}

export default Home