import { useEffect, useState,useRef } from "react";
import Navbar from "../../Navbar/Navbar";
import { useFormik } from "formik";
import useVisaStore from "../../../Stores/visaStoreCookie";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step1Det from "./stepDetails/Step1Det";
import Step2Det from "./stepDetails/Step2Det";
import Step3Det from "./stepDetails/Step3Det";
import Step4Det from "./stepDetails/Step4Det";
import Step5Det from "./stepDetails/Step5Det";
import Step6Det from "./stepDetails/Step6Det";
import Alert from "./alert/Alert";
import Navigation from "./Navigation/Navigation";
import Step6 from "./steps/Step6";
import Step7Det from "./stepDetails/Step7Det";
import Step7 from "./steps/Step7";
import { validationSchemas } from "./validations/validationSchemas.js";
import { useAuth } from "../../../context/AuthContext.js";
import useVisaApplicationStore from "../../../Stores/useVisaStore.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUserStore from "../../../Stores/userStore.js";
import Step8 from "./steps/Step8.jsx";
import Step8Det from "./stepDetails/Step8Det.jsx";
import useCountryStore from "../../../Stores/useCountriesStore.js";


const Apply = () => {
  const navigate = useNavigate();
  const {
    createVisaApplication,
    isLoading: isVisaLoading,
    error: visaError,
  } = useVisaApplicationStore();
  const {
    getCountries,
    isLoading: isCountryLoading,
    error: countryError,
  } = useCountryStore();
  const { fetchUserData } = useUserStore();
  const { authToken } = useAuth();
  const [userDetails, setUserDetails] = useState(null);
  const {
    setMultipleFormData,
    initializeForm,
    formData,
    nextStep,
    prevStep,
    currentStep,
    setStep,
    loading,
    clearFormData,
  } = useVisaStore();
  const [hasSavedProgress, setHasSavedProgress] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [tokenfup, setTokenfup] = useState(null);
  const [data, setData] = useState([]);
  const [countryDetails, setCountryDetails] = useState({});
  const [price,setPrice] = useState();
  const [discount, setDiscount] = useState();
  const[coupondiscount,setCoupondiscount] = useState();
  const discountRef = useRef(10);
  const [couponCode, setCpCode] = useState();
  // const [step, setStep] = useState(0);
  
  useEffect(() => {
    initializeForm();
  }, [initializeForm]);
  const handleContinue = () => {
    const savedStep = Cookies.get("currentStep");
    if (savedStep) {
      setStep(parseInt(savedStep, 10));
    }
  };
  useEffect(() => {
    const fetchcountrys = async () => {
      try {
        const res = await getCountries(); 
        if (res) {
          setData(res);
          console.log(res);
        }
      } catch (error) {
        // Handle error logging
      }
    };
    fetchcountrys();
  }, [getCountries]);

  useEffect(() => {
    const userData = fetchUserData();
    if (userData) {
      setUserDetails(userData.userDetails);
      setTokenfup(userData.token);
      console.log(userData); // Set the user details
    }
  }, [fetchUserData]);
  // console.log(formData);
  const steps = [
    "Choose Dates",
    "Personal Details",
    "Payment",
    "Confirmation",
    "Interview Location",
    "Choose your Plan",
    "Trip Details",
    "Documents Upload",
  ];

  useEffect(() => {
    if (currentStep === 7 && !authToken) {
      // Redirect to login if not authenticated
      Cookies.set("currentStep", currentStep); // Save the current step before redirect
      navigate("/login"); // Redirect to the login page
    }
  }, [authToken, currentStep, navigate]);
  const formik = useFormik({
    initialValues: {
      nationality: formData.nationality || "",
      applyingFor: formData.applyingFor || "",
      arrivedate: formData.arrivedate || "",
      leavedate: formData.leavedate || "",
      email: formData.email || "",
      travelers:
        Array.isArray(formData.travelers) && formData.travelers.length > 0
          ? formData.travelers
          : [
              {
                fname: "",
                lastname: "",
                dob: "",
                citizenship: "",
                birthCountry: "",
                residenceCountry: "",
                gender: "",
                passportNumber: "",
                passportIssuance: "",
                passportExpiry: "",
                occupation: "",
                income: "",
                assets: "",
                travelHistory: "",
                visaApplication: "",
                appointment: "",
              },
            ],
      processingTime: formData.processingTime || "",
      paymentMethod: formData.paymentMethod || "",
      cnic: null,
      passport: null,
      resume: null,
      price:"",
      coupondiscount:"",
      couponCode:"",
      travelerDiscount:"",

    },
    validationSchema: validationSchemas[currentStep - 1],
    onSubmit: async (values) => {
      try {
        const formattedTravelers = values.travelers.map((traveler) => ({
          fname: traveler.fname,
          lastname: traveler.lastname,
          dob: traveler.dob,
          citizenship: traveler.citizenship,
          birthCountry: traveler.birthCountry,
          residenceCountry: traveler.residenceCountry,
          gender: traveler.gender,
          passportNumber: traveler.passportNumber,
          passportIssuance: traveler.passportIssuance,
          passportExpiry: traveler.passportExpiry,
          occupation: traveler.occupation,
          income: traveler.income,
          assets: traveler.assets || "", 
          travelHistory: traveler.travelHistory || "", 
          visaApplication: traveler.visaApplication || "", 
          appointment: traveler.appointment || null, 
        }));

        if (formattedTravelers.length === 0) {
          throw new Error("At least one traveler must be provided.");
        }

       
        const formDataWithFiles = new FormData();
        formDataWithFiles.append(
          "userId",
          userDetails ? userDetails._id : null
        );
        formDataWithFiles.append("nationality", values.nationality);
        formDataWithFiles.append("applyingFor", values.applyingFor);
        formDataWithFiles.append("arrivedate", values.arrivedate);
        formDataWithFiles.append("leavedate", values.leavedate);
        formDataWithFiles.append("email", values.email);
        formDataWithFiles.append("processingTime", values.processingTime);
        formDataWithFiles.append("paymentMethod", values.paymentMethod);

        // Append payment detailsy6
        if (formData.paymentDetails) {
          const paymentDetails = formData.paymentDetails;
          formDataWithFiles.append(
            "paymentDetails[transactionId]",
            paymentDetails.transactionId
          );
          formDataWithFiles.append(
            "paymentDetails[payerInfo][id]",
            paymentDetails.payerInfo.id
          );
          formDataWithFiles.append(
            "paymentDetails[payerInfo][name]",
            paymentDetails.payerInfo.name
          );
          formDataWithFiles.append(
            "paymentDetails[payerInfo][email]",
            paymentDetails.payerInfo.email
          );
          formDataWithFiles.append(
            "paymentDetails[status]",
            paymentDetails.status
          );
          formDataWithFiles.append(
            "paymentDetails[amount]",
            paymentDetails.amount
          );
          formDataWithFiles.append(
            "paymentDetails[currency]",
            paymentDetails.currency
          );
        } else {
          throw new Error("Payment details are required.");
        }

        // Append files
        if (values.cnic) {
          formDataWithFiles.append("cnic", values.cnic);
        }
        if (values.passport) {
          formDataWithFiles.append("passport", values.passport);
        }
        if (values.resume) {
          formDataWithFiles.append("resume", values.resume);
        }

        // Append travelers array
        formattedTravelers.forEach((traveler, index) => {
          Object.keys(traveler).forEach((key) => {
            formDataWithFiles.append(
              `travelers[${index}][${key}]`,
              traveler[key]
            );
          });
        });

        formDataWithFiles.append("price", price);
    formDataWithFiles.append("discount", discount);
    formDataWithFiles.append("coupondiscount", coupondiscount);
    formDataWithFiles.append("couponCode", couponCode);
    formDataWithFiles.append("travelerDiscount", discountRef.current);
    formDataWithFiles.append("status", "inprogress"); 

        console.log("Form Data to be submitted:");
        for (let [key, value] of formDataWithFiles.entries()) {
          console.log(`${key}: ${value}`);
        }
        setMultipleFormData(values);
        const data = await createVisaApplication(formDataWithFiles, tokenfup);
        if (data) {
          toast.success("Visa Application Submitted!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          clearFormData();
        }
      } catch (error) {
        console.error("Error during form submission:", error); // Log the full error
        if (error.response) {
          console.error("Response data:", error.response.data); // Log response data for more details
          console.error("Response status:", error.response.status); // Log response status
        }
      }
    },
  });
  useEffect(() => {
    if (selectedCountry?.applicationFee != null) {
      const basePrice = selectedCountry.applicationFee;
      const travelerCount = formik.values.travelers.length;
  
      
      const travelerDiscount = travelerCount >= 2 ? discountRef.current : 0;
  
      
      const validCouponDiscount = coupondiscount || 0;
  
      const totalFee = basePrice * travelerCount;
  
      const discountedPrice =
        totalFee * (1 - travelerDiscount / 100) * (1 - validCouponDiscount / 100);
  
      setPrice(discountedPrice.toFixed(2));
      setDiscount(travelerDiscount); 
    } else {
      
      setPrice("0.00");
      setDiscount(0);
    }
  }, [selectedCountry, formik.values.travelers.length, coupondiscount]);
  


  useEffect(() => {
    const selectedCountryName = formik.values.applyingFor;
    const selectedCountryDetails = data.find((country) => country.name === selectedCountryName);
    
    if (selectedCountryDetails) {
      setSelectedCountry(selectedCountryDetails); 
    } else {
      setSelectedCountry(null); 
    }
  }, [formik.values.applyingFor, data]);

  const handleSubmit = async () => {
    const isValid = await formik.validateForm();

    if (Object.keys(isValid).length === 0) {
      // If no errors, proceed to the next step
      const updatedFormData = {
        ...formik.values,
        couponCode,         // Make sure this is defined
        coupondiscount,      // Make sure this is defined
      };
      setMultipleFormData(updatedFormData);
      nextStep();
    } else {
      // Otherwise, highlight the errors (Formik handles this automatically)
      formik.setTouched(isValid);
    }
  };

  const handlePre = () => {
    prevStep();
  };

  const handleDelete = async () => {
    await clearFormData();
    navigate("/");
  };
  const countryInfo = countryDetails || {};

  const handleTravelerChange = (index, field, value) => {
    const newTravelers = [...formik.values.travelers];
    newTravelers[index][field] = value;
    // setTravelers(newTravelers);
    formik.setFieldValue(`travelers[${index}].${field}`, value);
  };

  const addTraveler = () => {
    const newTraveler = {
      fname: "",
      lastname: "",
      dob: "",
      citizenship: "",
      birthCountry: "",
      residenceCountry: "",
      gender: "",
      passportNumber: "",
      passportIssuance: "",
      passportExpiry: "",
      occupation: "",
      income: "",
      assets: "",
      travelHistory: "",
      visaApplication: "",
      appointment: "",
    };
    const updatedTravelers = [...formik.values.travelers, newTraveler];
    // setTravelers(updatedTravelers);
    formik.setFieldValue(`travelers`, updatedTravelers); // Update Formik state
  };

  const removeTraveler = (index) => {
    const newTravelers = formik.values.travelers.filter((_, i) => i !== index);
    // setTravelers(newTravelers);
    formik.setFieldValue(`travelers`, newTravelers); // Update Formik state
  };

  useEffect(() => {
    // Handle Visa Application errors
    if (visaError) {
      console.error("Visa Error:", visaError);
      toast.error(`Visa Application Error: ${visaError}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
    if (countryError) {
      console.error("Country Error:", countryError);
      toast.error(`Country Fetch Error: ${countryError}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }, [visaError, countryError]);

  return (
    <>
      <Navbar />
      <div className="flex justify-center items-center min-h-screen flex-col">
        <Navigation
          hasSavedProgress={hasSavedProgress}
          handleContinue={handleContinue}
          currentStep={currentStep}
          steps={steps}
        />
        <div className="flex w-full max-w-full md:max-w-screen-xl gap-8 px-4 md:px-14 md:flex-row flex-col">
          <div className="flex-1 bg-white p-8 rounded-lg shadow-lg w-full ">
            <ToastContainer />
            <h1 className="text-2xl font-bold mb-4 pb-4 border-b border-gray-200 text-gray-600">
              Apply now for your {selectedCountry?.name} Visitor Visa
            </h1>

            {/* Alert Box */}
            <Alert formik={formik} />

            <form onSubmit={formik.handleSubmit}>
              {currentStep === 1 && (
                <>
                  <Step1 formik={formik} data={data} />
                </>
              )}
              {currentStep === 2 && (
                <>
                  <Step2 formik={formik} selectedCountry={selectedCountry} />
                </>
              )}
              {currentStep === 3 && (
                <Step3
                  formik={formik}
                  handleTravelerChange={handleTravelerChange}
                  addTraveler={addTraveler}
                  removeTraveler={removeTraveler}
                />
              )}
              {currentStep === 4 && (
                <Step4
                  formik={formik}
                  handleTravelerChange={handleTravelerChange}
                  removeTraveler={removeTraveler}
                />
              )}
              {currentStep === 5 && (
                <Step5
                  formik={formik}
                  handleTravelerChange={handleTravelerChange}
                  addTraveler={addTraveler}
                  removeTraveler={removeTraveler}
                />
              )}
              {currentStep === 6 && <Step6 formik={formik} />}
              {currentStep === 7 && (
                <Step7
                price={price}
                  formik={formik}
                  setPaymentCompleted={setPaymentCompleted}
                />
              )}
              {currentStep === 8 && <Step8 formik={formik} />}
              {paymentCompleted && currentStep === 8 && (
                <div className="flex flex-col md:flex-row md:justify-between items-center">
                  {/* Button */}
                  <div className="flex">
                    <button
                      type="submit"
                      className="bg-gradient-to-r from-[#25CBD6] to-[#00EE8A] rounded-lg py-2 px-4 font-semibold mb-2"
                      disabled={formik.isSubmitting}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </button>
                  </div>
                </div>
              )}
            </form>
            {currentStep === 1 && (
              <div className="flex flex-col md:flex-row md:justify-between items-center">
                <div className="flex">
                  <button
                    onClick={handleSubmit}
                    className="bg-gradient-to-r from-[#25CBD6] to-[#00EE8A] rounded-lg  font-semibold px-4 py-2"
                  >
                    {isVisaLoading || isCountryLoading ? (
                      <div className="flex space-x-2 animate-pulse">
                        <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
                        <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
                        <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
                      </div>
                    ) : (
                      "Start your application"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* Right Side */}
          {currentStep === 1 && (
            <Step1Det
              selectedCountry={selectedCountry}
              countryInfo={countryInfo}
              handleDelete={handleDelete}
            />
          )}
          {currentStep === 2 && (
            <Step2Det
              formik={formik}
              price={price}
              discount={discount}
              selectedCountry={selectedCountry}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
              handlePre={handlePre}
            />
          )}

          {currentStep === 3 && (
            <Step3Det
            
            price={price}
            discount={discount}
            setPrice={setPrice}
            selectedCountry={selectedCountry}
              formik={formik}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
              handlePre={handlePre}
            />
          )}
          {currentStep === 4 && (
            <Step4Det
            price={price}
            discount={discount}
            selectedCountry={selectedCountry}
              formik={formik}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
              handlePre={handlePre}
            />
          )}
          {currentStep === 5 && (
            <Step5Det
            price={price}
            discount={discount}
              formik={formik}
              selectedCountry={selectedCountry}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
              handlePre={handlePre}
            />
          )}
          {currentStep === 6 && (
            <Step6Det
            price={price}
            discount={discount}
              formik={formik}
              selectedCountry={selectedCountry}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
              handlePre={handlePre}
            />
          )}
          {currentStep === 7 && (
            <Step7Det
            price={price}
            discount={discount}
            setPrice ={setPrice}
            coupondiscount = {coupondiscount}
            setCoupondiscount ={setCoupondiscount}
            setCpCode={setCpCode}
              formik={formik}
              selectedCountry={selectedCountry}
              handleSubmit={handleSubmit}
              handlePre={handlePre}
              paymentCompleted={paymentCompleted}
            />
          )}
          {currentStep === 8 && (
            <Step8Det
              formik={formik}
              price={price}
              discount={discount}
              coupondiscount = {coupondiscount}
              selectedCountry={selectedCountry}
              handleSubmit={handleSubmit}
              handlePre={handlePre}
              paymentCompleted={paymentCompleted}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Apply;
