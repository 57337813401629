import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAdminStore from "../../../Stores/adminStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateProfile = () => {
  const [adminDetails, setAdminDetails] = useState(null);
  const { fetchAdminData, updateAdmin, isFetchingAdminData, isUpdatingAdmin, error } = useAdminStore();
  const [tokenfup, setTokenfup] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const adminData = await fetchAdminData();
      if (adminData) {
        setAdminDetails(adminData.adminDetails);
        setTokenfup(adminData.token);
        console.log(adminData)
      }
    };
    fetchUser();
  }, [fetchAdminData]);

  const formik = useFormik({
    initialValues: {
      firstname: adminDetails?.firstname || "",
      lastname: adminDetails?.lastname || "",
      email: adminDetails?.email || "",
      password: "",
      
      contact: adminDetails?.contact || "",
      department: adminDetails?.department || "",
      role: adminDetails?.role || "admin",
      status: adminDetails?.status || "active",
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .required("First name is required")
        .matches(/^[a-zA-Z\s]+$/, "First name should only contain letters and spaces"),
      lastname: Yup.string()
        .required("Last name is required")
        .matches(/^[a-zA-Z\s]+$/, "Last name should only contain letters and spaces"),
      email: Yup.string()
        .required("Email is required")
        .email("Please provide a valid email address"),
      password: Yup.string()
        .min(6, "Password should be at least 6 characters long"),
     
      contact: Yup.string()
        .matches(/^[0-9]+$/, "Contact number should only contain numbers"),
      department: Yup.string(),
      role: Yup.string()
        .oneOf(["superadmin", "admin", "moderator"], "Invalid role selected"),
      status: Yup.string()
        .oneOf(["active", "inactive", "suspended"], "Invalid status selected"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await updateAdmin(adminDetails._id, values, tokenfup);
        if (res) {
          toast.success("Admin Updated successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.error("Error updating", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error updating admin:", error);
      }
    },
  });

  useEffect(() => {
    if (adminDetails) {
      formik.setValues({
        firstname: adminDetails.firstname || "",
        lastname: adminDetails.lastname || "",
        email: adminDetails.email || "",
        password: "",
        
        contact: adminDetails.contact || "",
        department: adminDetails.department || "",
        role: adminDetails.role || "admin",
        status: adminDetails.status || "active",
      });
    }
  }, [adminDetails]);

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }, [error]);

  return (
    <>
      {isFetchingAdminData ? (
        <div className="flex justify-center items-center min-h-screen">
          <div className="relative">
            <div className="w-20 h-20 border-purple-200 border-2 rounded-full"></div>
            <div className="w-20 h-20 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
          </div>
        </div>
      ) : (
        <>
          <ToastContainer />
          <form onSubmit={formik.handleSubmit}>
            <div className="py-6 px-4 sm:p-6 lg:pb-8">
              <div>
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Update Profile
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  This information will be displayed publicly so be careful what you share.
                </p>
              </div>

              <div className="mt-6 grid grid-cols-12 gap-6">
                {/* First Name */}
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">First Name:</label>
                  <input
                    type="text"
                    id="firstname"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${formik.touched.firstname && formik.errors.firstname ? "border-red-500" : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"} sm:text-sm`}
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <div className="text-red-500 text-sm">{formik.errors.firstname}</div>
                  )}
                </div>

                {/* Last Name */}
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">Last Name:</label>
                  <input
                    type="text"
                    id="lastname"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${formik.touched.lastname && formik.errors.lastname ? "border-red-500" : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"} sm:text-sm`}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div className="text-red-500 text-sm">{formik.errors.lastname}</div>
                  )}
                </div>

                {/* Email */}
                <div className="col-span-12">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                  <input
                    type="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${formik.touched.email && formik.errors.email ? "border-red-500" : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"} sm:text-sm`}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-red-500 text-sm">{formik.errors.email}</div>
                  )}
                </div>

                {/* Password */}
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password:</label>
                  <input
                    type="password"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${formik.touched.password && formik.errors.password ? "border-red-500" : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"} sm:text-sm`}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-red-500 text-sm">{formik.errors.password}</div>
                  )}
                </div>

                {/* City */}
               

                {/* Contact */}
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact Number:</label>
                  <input
                    type="text"
                    id="contact"
                    value={formik.values.contact}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${formik.touched.contact && formik.errors.contact ? "border-red-500" : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"} sm:text-sm`}
                  />
                  {formik.touched.contact && formik.errors.contact && (
                    <div className="text-red-500 text-sm">{formik.errors.contact}</div>
                  )}
                </div>

                {/* Department */}
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="department" className="block text-sm font-medium text-gray-700">Department:</label>
                  <input
                    type="text"
                    id="department"
                    value={formik.values.department}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
                  />
                </div>

                {/* Role */}
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role:</label>
                  <select
                    id="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
                  >
                    <option value="superadmin">Superadmin</option>
                    <option value="admin">Admin</option>
                    <option value="moderator">Moderator</option>
                  </select>
                </div>

                {/* Status */}
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status:</label>
                  <select
                    id="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="suspended">Suspended</option>
                  </select>
                </div>
              </div>

              <div className="py-3 text-right">
                <button
                  type="submit"
                  disabled={isUpdatingAdmin}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md"
                >
                  {isUpdatingAdmin ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default UpdateProfile;
