import React from "react";

const Step2 = ({ formik, selectedCountry }) => {
  return (
    <>
      <div className="mb-4">
        <label className="block text-gray-600 text-sm font-medium mb-2">
          When you will arrive in {selectedCountry?.name}
        </label>
        <input
          type="date"
          className={`block w-full p-4 border rounded-lg ${
            formik.touched.arrivedate && formik.errors.arrivedate
              ? "border-red-500"
              : ""
          }`}
          id="arrivedate"
          name="arrivedate"
          value={formik.values.arrivedate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.arrivedate && formik.errors.arrivedate && (
          <p className="text-red-500 text-sm mt-1">
            {formik.errors.arrivedate}
          </p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-600 text-sm font-medium mb-2">
          When you will Leave from {selectedCountry?.name}
        </label>
        <input
          type="date"
          className={`block w-full p-4 border rounded-lg ${
            formik.touched.leavedate && formik.errors.leavedate
              ? "border-red-500"
              : ""
          }`}
          id="leavedate"
          name="leavedate"
          value={formik.values.leavedate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.leavedate && formik.errors.leavedate && (
          <p className="text-red-500 text-sm mt-1">{formik.errors.leavedate}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-600 text-sm font-medium mb-2">
          Email
        </label>
        <input
          type="text"
          className={`block w-full p-4 border rounded-lg ${
            formik.touched.email && formik.errors.email ? "border-red-500" : ""
          }`}
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email && (
          <p className="text-red-500 text-sm mt-1">{formik.errors.email}</p>
        )}
      </div>
    </>
  );
};

export default Step2;
