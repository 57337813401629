import React from 'react'
import { Link } from 'react-router-dom';
const DashboardNav = ({open,
    setOpen,
    activeLink,
    handleClick,
    logout,
    profileMenuOpen,
    setProfileMenuOpen,
    Logo}) => {
  return (
    <>
    
    <div className="relative overflow-hidden bg-sky-700 pb-32">
            <nav
              className={`bg-transparent relative z-10 border-b border-teal-500 border-opacity-25 lg:border-none lg:bg-transparent ${
                open ? "bg-sky-900" : "bg-transparent"
              }`}
            >
              <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-sky-800">
                  <div className="flex items-center px-2 lg:px-0">
                    <Link to={"/"}>
                      <div className="flex-shrink-0">
                        <img
                          className="block h-14 w-auto"
                          src={Logo}
                          alt="THA Europe"
                        />
                      </div>
                    </Link>
                    <div className="hidden lg:ml-6 lg:block lg:space-x-4">
                      <div className="flex">
                        <button
                          onClick={() => handleClick("Profile")}
                          className={` ${
                            activeLink === "Profile"
                              ? "bg-black bg-opacity-25"
                              : ""
                          } hover:bg-sky-800 rounded-md py-2 px-3 text-sm font-medium text-white`}
                        >
                          Dashboard
                        </button>
                        <button
                          onClick={() => handleClick("Myorders")}
                          className={` ${
                            activeLink === "Myorders"
                              ? "bg-black bg-opacity-25"
                              : ""
                          } hover:bg-sky-800 rounded-md py-2 px-3 text-sm font-medium text-white`}
                        >
                        Landing Page
                        </button>
                        <button
                          onClick={() => handleClick("Wishlist")}
                          className={` ${
                            activeLink === "Wishlist"
                              ? "bg-black bg-opacity-25"
                              : ""
                          } hover:bg-sky-800 rounded-md py-2 px-3 text-sm font-medium text-white`}
                        >
                          Wishlist
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                    <div className="w-full max-w-lg lg:max-w-xs">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative text-sky-100 focus-within:text-gray-400">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <svg
                            className="h-5 w-5 flex-shrink-0"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <input
                          id="search"
                          name="search"
                          className="block w-full rounded-md border border-transparent bg-sky-700 bg-opacity-50 py-2 pl-10 pr-3 leading-5 placeholder-sky-100 focus:border-white focus:bg-white focus:text-gray-900 focus:placeholder-gray-500 focus:outline-none focus:ring-white sm:text-sm"
                          placeholder="Search"
                          type="search"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex lg:hidden">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md p-2 text-sky-200 hover:bg-sky-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      aria-controls="mobile-menu"
                      onClick={() => setOpen(!open)}
                      aria-expanded={open}
                    >
                      <span className="sr-only">Open main menu</span>
                      <svg
                        className={`block h-6 w-6 flex-shrink-0 ${
                          open ? "hidden" : "block"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                      </svg>
                      <svg
                        className={`hidden h-6 w-6 flex-shrink-0 ${
                          open ? "block" : "hidden"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="hidden lg:ml-4 lg:block">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="flex-shrink-0 rounded-full p-1 text-sky-200 hover:bg-sky-800 hover:text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900"
                      >
                        <span className="sr-only">View notifications</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                          />
                        </svg>
                      </button>

                      <div className="relative ml-4 flex-shrink-0">
                        <div>
                          <button
                            type="button"
                            className="flex rounded-full text-sm text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900"
                            id="user-menu-button"
                            onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                            aria-expanded={profileMenuOpen}
                            aria-haspopup="true"
                          >
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src="https://via.placeholder.com/320x320.png?text=User+Image"
                              alt="Dummy UserImage"
                            />
                          </button>
                        </div>
                        {profileMenuOpen && (
                          <div
                            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="user-menu-button"
                            tabIndex="-1"
                          >
                            <button
                             onClick={() => logout()}
                              className="block py-2 px-4 text-sm text-gray-700"
                              role="menuitem"
                              tabIndex="-1"
                              id="user-menu-item-2"
                            >
                              Log out
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {open && (
                <div className="bg-sky-900 lg:hidden" id="mobile-menu">
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    <button
                      
                      className="bg-black bg-opacity-25 block rounded-md py-2 px-3 text-base font-medium text-white"
                    >
                      Dashboard
                    </button>
                    <button
                      onClick={() => handleClick("Myorders")}
                      className="hover:bg-sky-800 block rounded-md py-2 px-3 text-base font-medium text-white"
                    >
                      Orders
                    </button>
                    <button
                      
                      className={` ${
                        activeLink === "Wishlist"
                          ? "bg-black bg-opacity-25"
                          : ""
                      } hover:bg-sky-800 rounded-md py-2 px-3 text-sm font-medium text-white`}
                    >
                      Wishlist
                    </button>
                  </div>
                  <div className="border-t border-sky-800 pt-4 pb-3">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=320&amp;h=320&amp;q=80"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          Tom Cook
                        </div>
                        <div className="text-sm font-medium text-sky-200">
                          tom@example.com
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full p-1 text-sky-200 hover:bg-sky-800 hover:text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900"
                      >
                        <span className="sr-only">View notifications</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      <button
                       onClick={() => logout()}
                        className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-sky-800"
                      >
                        Log out
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </nav>
            <div
              aria-hidden="true"
              className={`absolute inset-x-0 left-1/2 w-full transform -translate-x-1/2 overflow-hidden lg:inset-y-0 ${
                open ? "bottom-0" : "inset-y-0"
              }`}
            >
              <div className="absolute inset-0 flex">
                <div
                  className="h-full w-1/2"
                  style={{ backgroundColor: "#0a527b" }}
                ></div>
                <div
                  className="h-full w-1/2"
                  style={{ backgroundColor: "#065d8c" }}
                ></div>
              </div>
              <div className="relative flex justify-center">
                <svg
                  className="flex-shrink-0"
                  width="1750"
                  height="308"
                  viewBox="0 0 1750 308"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M284.161 308H1465.84L875.001 182.413 284.161 308z"
                    fill="#0369a1"
                  />
                  <path
                    d="M1465.84 308L16.816 0H1750v308h-284.16z"
                    fill="#065d8c"
                  />
                  <path
                    d="M1733.19 0L284.161 308H0V0h1733.19z"
                    fill="#0a527b"
                  />
                  <path
                    d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z"
                    fill="#0a4f76"
                  />
                </svg>
              </div>
            </div>
            <header className="relative py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-white">
                  Welcome! 
                </h1>
              </div>
            </header>
          </div>
    </>
  )
}

export default DashboardNav