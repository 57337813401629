import React from 'react'
import Navbar from '../Navbar/Navbar'
import { Link } from 'react-router-dom'
import useUserStore from '../../Stores/userStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = () => {
  const {registerUser} = useUserStore();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      city: '',
      contact: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'First name can only contain letters and spaces')
        .required('First name is required'),
      lastname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Last name can only contain letters and spaces')
        .required('Last name is required'),
        
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      city: Yup.string()
      .matches(/^[A-Za-z\s,]+$/, 'City can only contain letters, spaces, and commas')
        .required('City is required'),
      contact: Yup.string()
        .required('Contact number is required')
        .matches(/^\d{11}$/, 'Contact must be a valid 11-digit number'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await registerUser(values);
        if(response){
        toast.success("User Created successful!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        formik.resetForm();
      }
      else{
        toast.error("Failed to sign up", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      }
      } catch (error) {
        console.error('Registration error:', error);
      }
    },
  });


  return (
    <>
    <Navbar />
    <ToastContainer />
     <div className='flex font-poppins items-center justify-center'>
     <div className="grid gap-8">
      <div
        id="back-div"
        className="bg-gradient-to-r from-blue-500 to-purple-500 rounded-[26px] m-4 "
      >
        <div
          className="border-[20px] border-transparent rounded-[20px]  bg-white shadow-lg xl:p-10 2xl:p-10 lg:p-10 md:p-10 sm:p-2 m-2"
        >
          <h1 className="pt-8 pb-6 font-bold text-5xl  text-center cursor-default">
            Sign Up
          </h1>
          <form onSubmit={formik.handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="firstname" className="mb-2 text-lg">First Name</label>
                  <input
                    id="firstname"
                    name="firstname"
                    type="text"
                    className={`border p-3 shadow-md placeholder:text-base border-gray-300 rounded-lg w-full focus:scale-105 ease-in-out duration-300 ${formik.errors.firstname && formik.touched.firstname ? 'border-red-500' : ''}`}
                    placeholder="First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname || ''}
                  />
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="text-red-500">{formik.errors.firstname}</div>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="lastname" className="mb-2 text-lg">Last Name</label>
                  <input
                    id="lastname"
                    name="lastname"
                    type="text"
                    className={`border p-3 shadow-md placeholder:text-base border-gray-300 rounded-lg w-full focus:scale-105 ease-in-out duration-300 ${formik.errors.lastname && formik.touched.lastname ? 'border-red-500' : ''}`}
                    placeholder="Last Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname || ''}
                  />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div className="text-red-500">{formik.errors.lastname}</div>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="email" className="mb-2 text-lg">Email</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className={`border p-3 shadow-md placeholder:text-base border-gray-300 rounded-lg w-full focus:scale-105 ease-in-out duration-300 ${formik.errors.email && formik.touched.email ? 'border-red-500' : ''}`}
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email }
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="password" className="mb-2 text-lg">Password</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    className={`border p-3 mb-2 shadow-md placeholder:text-base border-gray-300 rounded-lg w-full focus:scale-105 ease-in-out duration-300 ${formik.errors.password && formik.touched.password ? 'border-red-500' : ''}`}
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-500">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="city" className="mb-2 text-lg">City</label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    className={`border p-3 mb-2 shadow-md placeholder:text-base border-gray-300 rounded-lg w-full focus:scale-105 ease-in-out duration-300 ${formik.errors.city && formik.touched.city ? 'border-red-500' : ''}`}
                    placeholder="City containing your Country etc(Islamabad, Pakistan)"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="text-red-500">{formik.errors.city}</div>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="contact" className="mb-2 text-lg">Contact</label>
                  <input
                    id="contact"
                    name="contact"
                    type="text"
                    className={`border p-3 mb-2 shadow-md placeholder:text-base border-gray-300 rounded-lg w-full focus:scale-105 ease-in-out duration-300 ${formik.errors.contact && formik.touched.contact ? 'border-red-500' : ''}`}
                    placeholder="Contact Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact}
                  />
                  {formik.touched.contact && formik.errors.contact ? (
                    <div className="text-red-500">{formik.errors.contact}</div>
                  ) : null}
                </div>
                <button
                  className="bg-gradient-to-r from-blue-500 to-purple-500 shadow-lg mt-6 p-2 text-white rounded-lg w-full hover:scale-105 hover:from-purple-500 hover:to-blue-500 transition duration-300 ease-in-out"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  SIGN UP
                </button>
              </form>
          <div className="flex flex-col mt-4 items-center justify-center text-sm">
            <h3>
              <span className="cursor-default ">Have an account?</span>
              <Link
                className="group text-blue-400 transition-all duration-100 ease-in-out"
                to="/login"
              >
                <span
                  className="bg-left-bottom ml-1 bg-gradient-to-r from-blue-400 to-blue-400 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out"
                >
                  Log In
                </span>
              </Link>
            </h3>
          </div>

        
          <div
          id="third-party-auth"
          className="flex items-center justify-center mt-5 flex-wrap"
        >
          <button
            href="#"
            className="hover:scale-105 ease-in-out duration-300 shadow-lg p-2 rounded-lg m-1"
          >
            <img
              className="max-w-[25px]"
              src="https://ucarecdn.com/8f25a2ba-bdcf-4ff1-b596-088f330416ef/"
              alt="Google"
            />
          </button>
          <button
            href="#"
            className="hover:scale-105 ease-in-out duration-300 shadow-lg p-2 rounded-lg m-1"
          >
            <img
              className="max-w-[25px]"
              src="https://ucarecdn.com/95eebb9c-85cf-4d12-942f-3c40d7044dc6/"
              alt="Linkedin"
            />
          </button>
          <button
            href="#"
            className="hover:scale-105 ease-in-out duration-300 shadow-lg p-2 rounded-lg m-1"
          >
            <img
              className="max-w-[25px] filter "
              src="https://ucarecdn.com/be5b0ffd-85e8-4639-83a6-5162dfa15a16/"
              alt="Github"
            />
          </button>
          <button
            href="#"
            className="hover:scale-105 ease-in-out duration-300 shadow-lg p-2 rounded-lg m-1"
          >
            <img
              className="max-w-[25px]"
              src="https://ucarecdn.com/6f56c0f1-c9c0-4d72-b44d-51a79ff38ea9/"
              alt="Facebook"
            />
          </button>
          <button
            href="#"
            className="hover:scale-105 ease-in-out duration-300 shadow-lg p-2 rounded-lg m-1"
          >
            <img
              className="max-w-[25px] "
              src="https://ucarecdn.com/82d7ca0a-c380-44c4-ba24-658723e2ab07/"
              alt="twitter"
            />
          </button>

          <button
            href="#"
            className="hover:scale-105 ease-in-out duration-300 shadow-lg p-2 rounded-lg m-1"
          >
            <img
              className="max-w-[25px]"
              src="https://ucarecdn.com/3277d952-8e21-4aad-a2b7-d484dad531fb/"
              alt="apple"
            />
          </button>
        </div>
          <div
            className="text-gray-500 flex text-center flex-col mt-4 items-center text-sm"
          >
            <p className="cursor-default">
              By signing in, you agree to our
              <a
                className="group text-blue-400 transition-all duration-100 ease-in-out"
                href="#"
              >
                <span
                  className="cursor-pointer bg-left-bottom bg-gradient-to-r from-blue-400 to-blue-400 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out"
                >
                  Terms
                </span>
              </a>
              and
              <a
                className="group text-blue-400 transition-all duration-100 ease-in-out"
                href="#"
              >
                <span
                  className="cursor-pointer bg-left-bottom bg-gradient-to-r from-blue-400 to-blue-400 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out"
                >
                  Privacy Policy
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    
    </>
  )
}

export default SignUp