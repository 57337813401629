import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCountryStore from "../../../Stores/useCountriesStore"; // Assuming you have a store for countries
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAdminStore from "../../../Stores/adminStore";

const AddCountries = ({ countryId,token }) => {
  const [adminDetails, setAdminDetails] = useState(null);
  const { addCountry, updateCountry, getCountryById, isLoading, error } =
    useCountryStore();
  const [tokenfup, setTokenfup] = useState(null);
  const { fetchAdminData, isFetchingAdminData } = useAdminStore();
  const [initialValues, setInitialValues] = useState({
    name: "",
    visaTypes: "",
    visaValidationPeriod: "",
    maxStay: "",
    applicationFee: "",
    processingTime: "",
    visaRules: "",
    onlineApplicationAvailable: false,
    additionalInformation: "",
  });

  useEffect(() => {
    const fetchUser = async () => {
      const adminData = await fetchAdminData();
      if (adminData) {
        setTokenfup(adminData.token);
        console.log(tokenfup);
      }
    };
    fetchUser();
  }, [fetchAdminData]);
  useEffect(() => {
    if (countryId) {
      const fetchCountryData = async () => {
        const countryData = await getCountryById(countryId, token);
        console.log(countryData) // Fetch the country details
        if (countryData) {
          setInitialValues({
            name: countryData.name || "",
            visaTypes: countryData.visaTypes || "",
            visaValidationPeriod: countryData.visaValidationPeriod || "",
            maxStay: countryData.maxStay || "",
            applicationFee: countryData.applicationFee || "",
            processingTime: countryData.processingTime || "",
            visaRules: countryData.visaRules || "",
            onlineApplicationAvailable:
              countryData.onlineApplicationAvailable || false,
            additionalInformation: countryData.additionalInformation || "",
          });
        }
      };
      fetchCountryData();
    }
  }, [countryId, getCountryById]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Country name is required"),
      visaTypes: Yup.string().required("Visa type is required"),
      visaValidationPeriod: Yup.number()
        .required("Visa validation period is required")
        .min(1, "Period must be a positive number"),
      maxStay: Yup.number()
        .required("Maximum stay is required")
        .min(1, "Stay must be a positive number"),
      applicationFee: Yup.number().min(0, "Application fee can't be negative"),
      processingTime: Yup.number()
        .required("Processing time is required")
        .min(1, "Processing time must be at least 1 day"),
      visaRules: Yup.string(),
      onlineApplicationAvailable: Yup.boolean(),
      additionalInformation: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        if (countryId) {
          // If countryId is present, update the existing country
          const res = await updateCountry(countryId, values, tokenfup);
          if (res) {
            toast.success("Country updated successfully!", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else {
            toast.error("Error updating country", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        } else {
          // Otherwise, add a new country
          const res = await addCountry(values, tokenfup);
          if (res) {
            toast.success("Country added successfully!", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
            formik.resetForm();
          } else {
            toast.error("Error adding country", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      }
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }, [error]);

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Add New Country
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Provide the details for the new country and visa requirements.
            </p>
          </div>

          <div className="mt-6 grid grid-cols-12 gap-6">
            {/* Country Name */}
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Country Name:
              </label>
              <input
                type="text"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.name && formik.errors.name
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-red-500 text-sm">{formik.errors.name}</div>
              )}
            </div>

            {/* Visa Types */}
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="visaTypes"
                className="block text-sm font-medium text-gray-700"
              >
                Visa Types:
              </label>
              <input
                type="text"
                id="visaTypes"
                value={formik.values.visaTypes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.visaTypes && formik.errors.visaTypes
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.visaTypes && formik.errors.visaTypes && (
                <div className="text-red-500 text-sm">
                  {formik.errors.visaTypes}
                </div>
              )}
            </div>

            {/* Visa Validation Period */}
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="visaValidationPeriod"
                className="block text-sm font-medium text-gray-700"
              >
                Visa Validation Period (days):
              </label>
              <input
                type="number"
                id="visaValidationPeriod"
                value={formik.values.visaValidationPeriod}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.visaValidationPeriod &&
                  formik.errors.visaValidationPeriod
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.visaValidationPeriod &&
                formik.errors.visaValidationPeriod && (
                  <div className="text-red-500 text-sm">
                    {formik.errors.visaValidationPeriod}
                  </div>
                )}
            </div>

            {/* Maximum Stay */}
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="maxStay"
                className="block text-sm font-medium text-gray-700"
              >
                Maximum Stay (days):
              </label>
              <input
                type="number"
                id="maxStay"
                value={formik.values.maxStay}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.maxStay && formik.errors.maxStay
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.maxStay && formik.errors.maxStay && (
                <div className="text-red-500 text-sm">
                  {formik.errors.maxStay}
                </div>
              )}
            </div>

            {/* Application Fee */}
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="applicationFee"
                className="block text-sm font-medium text-gray-700"
              >
                Application Fee:
              </label>
              <input
                type="number"
                id="applicationFee"
                value={formik.values.applicationFee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.applicationFee && formik.errors.applicationFee
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.applicationFee &&
                formik.errors.applicationFee && (
                  <div className="text-red-500 text-sm">
                    {formik.errors.applicationFee}
                  </div>
                )}
            </div>

            {/* Processing Time */}
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="processingTime"
                className="block text-sm font-medium text-gray-700"
              >
                Processing Time (days):
              </label>
              <input
                type="number"
                id="processingTime"
                value={formik.values.processingTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.processingTime && formik.errors.processingTime
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.processingTime &&
                formik.errors.processingTime && (
                  <div className="text-red-500 text-sm">
                    {formik.errors.processingTime}
                  </div>
                )}
            </div>

            {/* Visa Rules */}
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="visaRules"
                className="block text-sm font-medium text-gray-700"
              >
                Visa Rules:
              </label>
              <input
                type="text"
                id="visaRules"
                value={formik.values.visaRules}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.visaRules && formik.errors.visaRules
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.visaRules && formik.errors.visaRules && (
                <div className="text-red-500 text-sm">
                  {formik.errors.visaRules}
                </div>
              )}
            </div>

            {/* Online Application */}
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="onlineApplicationAvailable"
                className="block text-sm font-medium text-gray-700"
              >
                Online Application Available:
              </label>
              <input
                type="checkbox"
                id="onlineApplicationAvailable"
                value={formik.values.onlineApplicationAvailable}
                onChange={formik.handleChange}
                className={`mt-1`}
              />
            </div>

            {/* Additional Information */}
            <div className="col-span-12">
              <label
                htmlFor="additionalInformation"
                className="block text-sm font-medium text-gray-700"
              >
                Additional Information:
              </label>
              <textarea
                id="additionalInformation"
                value={formik.values.additionalInformation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.additionalInformation &&
                  formik.errors.additionalInformation
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.additionalInformation &&
                formik.errors.additionalInformation && (
                  <div className="text-red-500 text-sm">
                    {formik.errors.additionalInformation}
                  </div>
                )}
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading
                ? countryId
                  ? "Updating..."
                  : "Adding..."
                : countryId
                ? "Update Country"
                : "Add Country"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCountries;
