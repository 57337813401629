import React from 'react'

const Alert = ({formik}) => {
  return (
  <>
   {formik.values.nationality === "Pakistan" && (
              <div className="flex items-center bg-blue-200 p-4 rounded-lg mb-4">
                <svg
                  className="rh pf "
                  width="49"
                  height="48"
                  viewBox="0 0 49 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.29883 4.79883H40.7184C42.3335 4.79883 43.6137 6.16153 43.5129 7.77349L42.4988 23.9988H6.49883L5.29883 4.79883Z"
                    fill="#0B3947"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.9657 13.8841C19.3728 11.8894 20.8825 10.2773 22.811 9.71169C22.1097 10.9695 21.67 12.3986 21.5235 13.8841H18.9657ZM18.8894 15.5508C19.134 17.7466 20.7348 19.5112 22.8592 19.9873C22.1013 18.6589 21.638 17.1341 21.5073 15.5508H18.8894ZM23.1806 15.5508C23.3039 16.8225 23.6782 18.0325 24.2635 19.0844C24.8489 18.0325 25.2232 16.8225 25.3465 15.5508H23.1806ZM27.0198 15.5508C26.8944 17.0699 26.4628 18.5353 25.7585 19.8249C27.6328 19.1847 29.0706 17.5456 29.41 15.5508H27.0198ZM29.4316 13.8841H27.0036C26.8556 12.3833 26.4083 10.9401 25.6943 9.67286C27.6582 10.2003 29.1346 11.8416 29.4316 13.8841ZM25.3275 13.8841H23.1996C23.3395 12.6778 23.7057 11.5317 24.2635 10.5291C24.8214 11.5317 25.1876 12.6778 25.3275 13.8841ZM17.1925 14.8042C17.3057 10.9514 20.522 7.82812 24.3765 7.82812C28.2309 7.82812 31.2638 10.9514 31.1507 14.8042C31.0376 18.657 27.8212 21.7803 23.9668 21.7803C20.1123 21.7803 17.0794 18.657 17.1925 14.8042Z"
                    fill="#00D474"
                  ></path>
                  <path
                    d="M6.49883 24H42.4988L43.6988 43.2H8.27929C6.66419 43.2 5.384 41.8373 5.48474 40.2253L6.49883 24Z"
                    fill="#00D474"
                  ></path>
                  <path
                    d="M24.4837 30.0155V29.3822H38.3171V30.0155H24.4837Z"
                    fill="#0B3947"
                    stroke="#0B3947"
                    strokeWidth="1.16667"
                  ></path>
                  <path
                    d="M24.4837 33.6171V32.9837H38.3171V33.6171H24.4837Z"
                    fill="#0B3947"
                    stroke="#0B3947"
                    strokeWidth="1.16667"
                  ></path>
                  <path
                    d="M24.4837 37.2167V36.5833H32.9171V37.2167H24.4837Z"
                    fill="#0B3947"
                    stroke="#0B3947"
                    strokeWidth="1.16667"
                  ></path>
                  <path
                    d="M15.0205 34C16.7879 34 18.2207 32.5672 18.2207 30.7998C18.2207 29.0324 16.7879 27.5996 15.0205 27.5996C13.2531 27.5996 11.8203 29.0324 11.8203 30.7998C11.8203 32.5672 13.2531 34 15.0205 34Z"
                    fill="#0B3947"
                  ></path>
                  <path
                    d="M10.2207 36.1988C10.2207 35.4256 10.8475 34.7988 11.6207 34.7988H18.4213C19.1945 34.7988 19.8213 35.4256 19.8213 36.1988V39.5991H10.2207V36.1988Z"
                    fill="#0B3947"
                  ></path>
                </svg>
                <div className="flex-col pl-2">
                  <h1>Visa Required</h1>
                  <span>
                    You need a visa to travel to Australia if you have a
                    passport from Pakistan.
                  </span>
                </div>
              </div>
            )}
  </>
  )
}

export default Alert