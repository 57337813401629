import React from "react";
import { FaArrowLeft } from "react-icons/fa";
const Step2Det = ({ formik, handleSubmit, handleDelete, handlePre,selectedCountry,price,discount }) => {
  
  return (
    <>
      <div className="md:sticky md:top-28 h-[400px] md:w-80 bg-white flex flex-col ">
      <div className="flex flex-col border border-gray-200 rounded-xl bg-gray-100 py-4 px-4">
          <div className="flex justify-between items-center mb-2 ">
            <h2 className="text-gray-700 font-semibold">
            {selectedCountry?.name}{" "}{selectedCountry?.visaTypes} Visa
            </h2>
            <span className="text-gray-600">
              {formik.values.travelers.length} traveler
              {formik.values.travelers.length > 1 ? "s" : ""}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-gray-500 text-sm">Fees</div>
            <span className="text-lg font-bold text-gray-800"> £{price}</span>
          </div>
          {formik.values.travelers.length >= 2 && (
      <div className="text-sm text-gray-500">
        Discount applied: {discount}% off
      </div>
    )}
        </div>
        <div className="space-y-1 py-8 p-4">
          <div className="flex justify-between font-semibold text-sm md:text-lg">
            <span>Total</span>
            <span>Calculated at checkout</span>
          </div>

          <div className="flex justify-between text-xs md:text-sm text-gray-500">
            <span>For all travelers</span>
            <span>(Includes taxes and fees)</span>
          </div>
        </div>

        <div className="space-y-4">
          <div className="mt-4">
            <button
              onClick={handleSubmit}
              className="w-full bg-gradient-to-r from-teal-400 to-green-500 font-medium py-3 rounded-lg shadow-md"
            >
              Save and continue
            </button>
          </div>
          <div className="text-sm text-gray-500 mt-2">
            <span className="flex items-center">
              <svg
                className="ra pe "
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.8 16.4983C13.2852 16.4983 14.7096 15.8926 15.7598 14.8144C16.81 13.7363 17.4 12.2739 17.4 10.7492C17.4 9.22439 16.81 7.76207 15.7598 6.68389C14.7096 5.60571 13.2852 5 11.8 5C10.3148 5 8.89041 5.60571 7.8402 6.68389C6.79 7.76207 6.2 9.22439 6.2 10.7492C6.2 12.2739 6.79 13.7363 7.8402 14.8144C8.89041 15.8926 10.3148 16.4983 11.8 16.4983ZM9.80062 18.6543C5.49125 18.6543 2 22.2385 2 26.6626C2 27.3993 2.58188 27.9966 3.29938 27.9966H20.3006C20.3794 27.9966 20.4538 27.9876 20.5325 27.9742C17.1944 25.4993 16.1663 21.6411 16.0219 18.9821C15.3175 18.7665 14.5737 18.6543 13.8037 18.6543H9.80062ZM23.3106 15.1374L18.0606 17.2933C17.6625 17.4595 17.4 17.8548 17.4 18.2949C17.4 21.1381 18.5331 25.8766 23.2975 27.9158C23.5556 28.0281 23.8487 28.0281 24.1069 27.9158C28.8669 25.8766 30 21.1381 30 18.2949C30 17.8548 29.7375 17.4595 29.3394 17.2933L24.0894 15.1374C23.84 15.0341 23.56 15.0341 23.3106 15.1374ZM27.8738 19.0136C27.7031 21.2908 26.6838 24.2552 23.7 25.7374V17.2978L27.8738 19.0136Z"></path>
              </svg>
              Your info is safe with us!
            </span>
            <a href="#" className="text-blue-500 underline">
              THA Europe data security promise
            </a>
          </div>
          <div className="mt-4">
            <button
              onClick={handleDelete}
              className="w-full bg-gradient-to-r from-red-400 to-red-500 text-white font-medium py-3 rounded-lg shadow-md"
            >
              Delete My application
            </button>
          </div>

         
        </div>

        <button
          onClick={handlePre}
          className="text-indigo-600 mt-4 inline-flex items-center font-semibold text-2xl"
        >
          <FaArrowLeft className="mr-2" />
          Previous
        </button>
      </div>
    </>
  );
};

export default Step2Det;
