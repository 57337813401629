import React from "react";

const Step1 = ({ formik ,data}) => {
  return (
    <>
      <div className="mb-4">
        <label className="block text-gray-600 text-sm font-medium mb-2">
          What is your nationality?
        </label>
        <select
          className={`block w-full p-4 border rounded-lg ${
            formik.touched.nationality && formik.errors.nationality
              ? "border-red-500"
              : ""
          }`}
          id="nationality"
          name="nationality"
          value={formik.values.nationality}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">Select your nationality</option>
          <option value="USA">USA</option>
          <option value="Canada">Canada</option>
          <option value="UK">UK</option>
          <option value="Australia">Australia</option>
          <option value="Pakistan">Pakistan</option>
          {/* Add more options as needed */}
        </select>
        {formik.touched.nationality && formik.errors.nationality && (
          <p className="text-red-500 text-sm mt-1">
            {formik.errors.nationality}
          </p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-gray-600 text-sm font-medium mb-2">
          Applying for
        </label>
        
        <select
          className={`block w-full p-4 border rounded-lg ${
            formik.touched.applyingFor && formik.errors.applyingFor
              ? "border-red-500"
              : ""
          }`}
          id="applyingFor"
          name="applyingFor"
          value={formik.values.applyingFor}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">Select a Country</option>
          {data.map((country) => (
          <option key={country._id} value={country.name}>
            {country.name}
          </option>
        ))}
          {/* Add more options as needed */}
        </select>
        {formik.touched.applyingFor && formik.errors.applyingFor && (
          <p className="text-red-500 text-sm mt-1">
            {formik.errors.applyingFor}
          </p>
        )}
      </div>
    </>
  );
};

export default Step1;
