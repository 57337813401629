import React from 'react'
import { FaTrash } from 'react-icons/fa';  // Import FaTrash
import { FiPlus } from 'react-icons/fi';
const Step3 = ({ formik, handleTravelerChange, addTraveler, removeTraveler }) => {
  return (
    <>
    {formik.values.travelers.map((traveler, index) => (
        
      <div key={index} className="flex flex-col gap-4 mb-6">
        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-medium mb-2">
            First and Middle name
          </label>
          <input
            type="text"
            value={traveler.fname}
            onChange={(e) =>
              handleTravelerChange(index, "fname", e.target.value)
            }
            className={`block w-full p-4 border rounded-lg ${
              formik.touched.travelers &&
              formik.touched.travelers[index]?.fname &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.fname
                ? "border-red-500"
                : ""
            }`}
            placeholder="Name"
          />
          <span className="text-red-500 text-sm mt-1">
            {formik.touched.travelers &&
              formik.touched.travelers[index]?.fname &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.fname}
          </span>
        </div>
        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-medium mb-2">
            Last name
          </label>
          <input
            type="text"
            value={traveler.lastname}
            onChange={(e) =>
              handleTravelerChange(
                index,
                "lastname",
                e.target.value
              )
            }
            className={`block w-full p-4 border rounded-lg ${
              formik.touched.travelers &&
              formik.touched.travelers[index]?.lastname &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.lastname
                ? "border-red-500"
                : ""
            }`}
            placeholder="Last Name"
          />
          <span className="text-red-500 text-sm mt-1">
            {formik.touched.travelers &&
              formik.touched.travelers[index]?.lastname &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.lastname}
          </span>
        </div>
        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-medium mb-2">
            DOB
          </label>
          <input
            type="date"
            value={traveler.dob}
            onChange={(e) =>
              handleTravelerChange(index, "dob", e.target.value)
            }
            className={`block w-full p-4 border rounded-lg ${
              formik.touched.travelers &&
              formik.touched.travelers[index]?.dob &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.dob
                ? "border-red-500"
                : ""
            }`}
          />
          <span className="text-red-500 text-sm mt-1">
            {formik.touched.travelers &&
              formik.touched.travelers[index]?.dob &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.dob}
          </span>
        </div>

        <button
          type="button"
          onClick={() => removeTraveler(index)}
        >
          <FaTrash className="h-8 w-8 text-black" />
        </button>
      </div>
    ))}
    <button
      type="button"
      className="bg-gradient-to-r from-teal-400 to-green-500 text-white p-2 mt-4 w-full rounded-lg py-4 flex items-center justify-center"
      onClick={addTraveler}
    >
      <FiPlus className="h-8 w-8 text-black text-center " /> Add
      Traveler
    </button>
  </>
  )
}

export default Step3