import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAdminAuth } from '../context/AdminAuthProvider';// Adjust the import path accordingly

const AdminPrivateRoute = ({ element }) => {
  const { adminToken } = useAdminAuth(); // Get admin token from context

  return adminToken ? element : <Navigate to="/admin-login" />;
};

export default AdminPrivateRoute;
