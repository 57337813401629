import React, { useState, useEffect } from "react";
import useVisaApplicationStore from "../../../Stores/useVisaStore";
import useUserStore from "../../../Stores/userStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';

const MyOrders = () => {
  const [userDetails, setUserDetails] = useState(null);
  const { getVisaApplicationsByUserId,isLoading: isVisaLoading } = useVisaApplicationStore();
  const { fetchUserData, isFetchingUserData, error } = useUserStore();
  const [tokenfup, setTokenfup] = useState(null);
  const [data, setData] = useState(null);
  
  useEffect(() => {
    const fetchUser = async () => {
      const userData = await fetchUserData(); // Await the user data fetching
      if (userData) {
        setUserDetails(userData.userDetails);
        setTokenfup(userData.token);
      }
    };
    fetchUser();
  }, [fetchUserData]);

  useEffect(() => {
    const fetchVisaApplications = async () => {
      if (userDetails && tokenfup) {
        try {
          const abc = await getVisaApplicationsByUserId(
            userDetails._id,
            tokenfup
          );
          if (abc) {
            setData(abc);
            console.log(abc); // data will be logged correctly now
          }
        } catch (error) {
          console.error("Error fetching visa application:", error);
        }
      }
    };
    fetchVisaApplications();
  }, [userDetails, tokenfup]); // Trigger when userDetails or tokenfup change

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }, [error]);
  const isLoading = isFetchingUserData || isVisaLoading;
  return (
    <>
      <ToastContainer />
      {isLoading ? (
        <>
       <div className="flex justify-center items-center min-h-screen">
    <div className="relative">
      <div className="w-20 h-20 border-purple-200 border-2 rounded-full"></div>
      <div className="w-20 h-20 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
    </div>

    <div className="relative mx-4">
      <div className="w-10 h-10 border-purple-200 border-2 rounded-full"></div>
      <div className="w-10 h-10 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
    </div>

    <div className="relative">
      <div className="w-5 h-5 border-purple-200 border-2 rounded-full"></div>
      <div className="w-5 h-5 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
    </div>
  </div>
    </>
      ): (
        <div className="mx-auto container px-4 md:px-6 2xl:px-0 py-12 flex justify-center items-center">
        <div className="flex flex-col jusitfy-start items-start">
          <section className="container px-4 mx-auto">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div>
                <div className="flex items-center gap-x-3">
                  <h2 className="text-lg font-medium text-gray-800 ">
                    My Orders
                  </h2>

                  <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">
                  {data ? data.length : 0} applications
                  </span>
                </div>

                <p className="mt-1 text-sm text-gray-500 ">
                  Check for the status of your application(s)
                </p>
              </div>
            </div>

            <div className="mt-6 md:flex md:items-center md:justify-between">
              <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg ">
                <button className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm ">
                  View all
                </button>

                <button className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm ">
                  Monitored
                </button>

                <button className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm ">
                  Unmonitored
                </button>
              </div>

              <div className="relative flex items-center mt-4 md:mt-0">
                <span className="absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 mx-3 text-gray-400 "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>

                <input
                  type="text"
                  placeholder="Search"
                  className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5  focus:border-blue-400  focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>

            <div className="flex flex-col mt-6">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden border border-gray-200 ">
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            <span>Nationality</span>
                          </th>

                          <th
                            scope="col"
                            className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            Applying for
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            Leave Date
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            arrivedate
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            {" "}
                            Payment Method
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            {" "}
                            Processing Time
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            {" "}
                            Actions
                          </th>
                          <th scope="col" className="relative py-3.5 px-4">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead> 
                      <tbody className="bg-white divide-y divide-gray-200 ">
                        {data?.map((application, index) => (
                          <tr key={index}>
                            <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                              <div>
                                <a
                                  href={`http://localhost:7000/${application.resumePath}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Resume
                                </a>
                                
                                <p className="text-sm font-normal text-gray-600 ">
                                  {application.nationality}
                                </p>
                              </div>
                            </td>
                            <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                              <div className="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                               
                              {application.applyingFor}
                                
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div>
                                <h4 className="text-gray-700 ">
                                {moment(application.leavedate).format("MMMM Do YYYY")}
                                </h4>
                                
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div className="flex items-center">
                               
                                <p className=" text-blue-600 bg-blue-100 border-2 border-white rounded-full">
                                {moment(application.leavedate).format("MMMM Do YYYY")}
                                </p>
                              </div>
                            </td>

                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                            <p className=" ">
                                {application.paymentMethod}
                                </p>
                              <div className="w-48 h-1.5 bg-blue-200 overflow-hidden rounded-full">
                                <div className="bg-blue-500 w-2/3 h-1.5"></div>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                            <p className="  ">
                                {application.processingTime}
                                </p>
                              
                            </td>

                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <button className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
              <div className="text-sm text-gray-500 ">
                Page <span className="font-medium text-gray-700 ">1 of 10</span>
              </div>

              <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                <a
                  href="#"
                  className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 rtl:-scale-x-100"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                    />
                  </svg>

                  <span>previous</span>
                </a>

                <a
                  href="#"
                  className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 "
                >
                  <span>Next</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 rtl:-scale-x-100"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
      )}
     
    </>
  );
};

export default MyOrders;
