import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

// Create the context
const AdminAuthContext = createContext();

// Create a provider component
export const AdminAuthProvider = ({ children }) => {
  const [adminToken, setAdminToken] = useState(Cookies.get('adminAuthToken')); // Use adminAuthToken
  const [adminDetails, setAdminDetails] = useState(Cookies.get('adminDetails'));

  // Update token state whenever it changes in cookies
  useEffect(() => {
    const token = Cookies.get('adminAuthToken');
    if (token) {
      setAdminToken(token);
    }
  }, []);

  useEffect(() => {
    const details = Cookies.get('adminDetails');
    if (details) {
      setAdminDetails(details);
    }
  }, []);

  // Update admin token function
  const updateAdminToken = (token) => {
    Cookies.set('adminAuthToken', token, { expires: 1/24, secure: true, sameSite: 'Strict' });
    setAdminToken(token);
  };

  // Update admin details function
  const updateAdminDetails = (details) => {
    Cookies.set('adminDetails', details, { expires: 1/24, secure: true, sameSite: 'Strict' });
    setAdminDetails(details);
  };

  return (
    <AdminAuthContext.Provider value={{ adminToken, adminDetails, updateAdminToken, updateAdminDetails }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

// Custom hook to use the AdminAuth context
export const useAdminAuth = () => useContext(AdminAuthContext);
