import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../Stores/userStore";
import { useAuth } from "../../context/AuthContext";
import Cookies from "js-cookie";

const LoginGoogle = () => {
    const navigate = useNavigate();
    const { signupWithGoogle } = useUserStore();
    const {updateUserToken} = useAuth();
  
    const login = useGoogleLogin({
      onSuccess: async (tokenResponse) => {
        try {
          // Make a request to get user information using the access token
          const response = await axios.get(
            "https://www.googleapis.com/oauth2/v1/userinfo",
            {
              headers: {
                Authorization: `Bearer ${tokenResponse.access_token}`,
              },
            }
          );
          // future m kam a skta
          // setUserInfo(response.data);
  
          const googleResponse = await signupWithGoogle(response.data);
      
          updateUserToken(googleResponse.token);
  

          if (googleResponse) {
            const savedStep = Cookies.get("currentStep");
            if(savedStep){
              navigate("/apply");
            }else{
            navigate("/dashboard");
            }
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      },
    });
  return (
    <div>
    <button
 className="hover:scale-105 ease-in-out duration-300 shadow-lg p-2 rounded-lg m-1"
 onClick={() => login()}
>
 <FcGoogle className="max-w-[25px]" /> 
</button>

   </div>
  )
}

export default LoginGoogle