import React from 'react'

const Navigation = ({ hasSavedProgress, handleContinue, currentStep, steps }) => {
  return (
    <>
    <div className="p-4 space-y-2 ">
          {hasSavedProgress && (
            <div className="alert alert-warning">
              <p>
                Your application is still in progress. You haven't completed it
                yet.
              </p>
              <button onClick={handleContinue} className="btn btn-primary">
                Go to Your Application
              </button>
            </div>
          )}
          <h3 className="text-base font-semibold ">
            {currentStep === 0
              ? "Start"
              : `Step ${currentStep}: ${steps[currentStep - 1]}`}
          </h3>
          <div className="flex max-w-xs space-x-3">
            {steps.map((_, index) => (
              <span
                key={index}
                className={`w-4 md:w-24 h-2 rounded-full ${
                  index < currentStep - 1 ? "bg-violet-600" : "bg-gray-400"
                } ${index === currentStep - 1 ? "animate-pulse" : ""}`}
              ></span>
            ))}
          </div>
        </div>
        </>
  )
}

export default Navigation