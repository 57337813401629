import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from "react-icons/fa";

const ScrolltoTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Function to scroll to the top of the page smoothly
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // On scroll event, toggle button visibility based on scroll position
    const handleScroll = () => {
        if (window.pageYOffset > 500) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleWhatsAppClick = () => {
        // Replace 'your-message-here' with any initial message you want to send
        window.open(`https://wa.me/923005200104?text=Your%20message%20here`);
    };
  return (
   <>
    {isVisible && (
        <>
        
        <button
            onClick={handleWhatsAppClick}
            className="animate-bounce fixed  z-50 bottom-28 right-5 p-4 border-0 w-14 h-14 rounded-full shadow-lg bg-green-500 hover:bg-green-600 text-white text-lg font-semibold transition-colors duration-300 focus:outline-none focus:ring focus:ring-green-400"
            title="Contact via WhatsApp"
        >
            <FaWhatsapp className=" w-6 h-6" />
            
            {/* <span className="absolute bottom-1/4 top-1/5 left-1/4 right-1/4 bg-green-400 rounded-full w-10 h-10 animate-ping"></span>
             */}
        </button>
               <button
               id="to-top-button"
               onClick={goToTop}
               title="Go To Top"
               className="fixed z-50 bottom-10 right-5 p-4 border-0 w-14 h-14 rounded-full shadow-lg bg-gradient-to-r from-cyan-400 to-cyan-600 text-white text-lg font-semibold transition-colors duration-300 focus:outline-none focus:ring focus:ring-cyan-900"
           >
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                   <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
               </svg>
               <span className="sr-only">Go to top</span>
           </button>
          </>  
        )}
   </>
  )
}

export default ScrolltoTop