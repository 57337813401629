import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const useHeroSectionStore = create(
  devtools((set) => ({
    heroSection: null,
    isLoading: false,
    isFetchingHeroSection: false,
    isUpdatingHeroSection: false,
    error: null,

    // Fetch the Hero section content
    fetchHeroSection: async () => {
      try {
        set({ isFetchingHeroSection: true, error: null });
        const response = await axios.get('http://localhost:7000/api/herosection');
        set({ heroSection: response.data, isFetchingHeroSection: false });
      } catch (error) {
        console.error('Error fetching hero section:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isFetchingHeroSection: false,
        });
      }
    },

    // Create or update the Hero section
    createOrUpdateHeroSection: async (heroData, token) => {
      try {
        set({ isUpdatingHeroSection: true, error: null });
        
        const response = await axios.post('http://localhost:7000/api/herosection', heroData, {
          headers: {
            Authorization: `Bearer ${token}`, // Only token is added in headers
          },
        });
        
        set({ heroSection: response.data, isUpdatingHeroSection: false });
        return response.data; // Return the updated or created Hero section
      } catch (error) {
        console.error('Error updating hero section:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isUpdatingHeroSection: false,
        });
        throw error;
      }
    },

    // Clear the Hero section data
    clearHeroSection: () => {
      set({ heroSection: null });
    },
  }))
);

export default useHeroSectionStore;
