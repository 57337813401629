import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAdminStore from "../../../../Stores/adminStore";
import useHeroSectionStore from "../../../../Stores/frontend/landingpage/heroStore";

const HeroSection = () => {
  const { fetchAdminData, isFetchingAdminData, error } = useAdminStore();
  const {
    createOrUpdateHeroSection,
    fetchHeroSection,
    heroSection,
    isFetchingHeroSection,
    isUpdatingHeroSection,
  } = useHeroSectionStore();
  const [tokenfup, setTokenfup] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const adminData = await fetchAdminData();
      if (adminData) {
        setTokenfup(adminData.token);
        console.log(adminData);
      }
    };
    fetchUser();
  }, [fetchAdminData]);
  useEffect(() => {
    const getHeroSection = async () => {
      await fetchHeroSection();
    };
    getHeroSection();
  }, [fetchHeroSection]);

  const formik = useFormik({
    initialValues: {
      heading: heroSection?.heading || "",
      mainText: heroSection?.mainText || "",
      supportingText: heroSection?.supportingText || "",
    },
    validationSchema: Yup.object({
      heading: Yup.string()
        .required("Heading is required")
        .min(1, "Heading should not be empty"),
      mainText: Yup.string().required("Main text is required"),
      supportingText: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        const res = await createOrUpdateHeroSection(values, tokenfup);
        if (res) {
          toast.success("Hero section updated successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.error("Error updating hero section", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error adding admin:", error);
      }
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }, [error]);
  return (
    <>
      {isFetchingAdminData || isFetchingHeroSection ? (
        <div className="flex justify-center items-center min-h-screen">
          <div className="relative">
            <div className="w-20 h-20 border-purple-200 border-2 rounded-full"></div>
            <div className="w-20 h-20 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
          </div>
        </div>
      ) : (
        <>
          <ToastContainer />
          <form onSubmit={formik.handleSubmit}>
            <div className="py-6 px-4 sm:p-6 lg:pb-8">
              <div>
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Update Hero Section
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Fill in the details to update the hero section.
                </p>
              </div>

              <div className="mt-6 grid grid-cols-12 gap-6">
                {/* Heading */}
                <div className="col-span-12">
                  <label
                    htmlFor="heading"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Heading:
                  </label>
                  <input
                    type="text"
                    id="heading"
                    value={formik.values.heading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                      formik.touched.heading && formik.errors.heading
                        ? "border-red-500"
                        : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                    } sm:text-sm`}
                  />
                  {formik.touched.heading && formik.errors.heading && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.heading}
                    </div>
                  )}
                </div>

                {/* Main Text */}
                <div className="col-span-12">
                  <label
                    htmlFor="mainText"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Main Text:
                  </label>
                  <textarea
                    id="mainText"
                    value={formik.values.mainText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                      formik.touched.mainText && formik.errors.mainText
                        ? "border-red-500"
                        : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                    } sm:text-sm`}
                  />
                  {formik.touched.mainText && formik.errors.mainText && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.mainText}
                    </div>
                  )}
                </div>

                {/* Supporting Text */}
                <div className="col-span-12">
                  <label
                    htmlFor="supportingText"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Supporting Text:
                  </label>
                  <textarea
                    id="supportingText"
                    value={formik.values.supportingText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
                  />
                </div>
              </div>

              <div className="py-3 text-right">
                <button
                  type="submit"
                  disabled={isUpdatingHeroSection}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md"
                >
                  {isUpdatingHeroSection
                    ? "Updating..."
                    : "Update Hero Section"}
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default HeroSection;
