// countryData.js
import usa from './images/usa.jpeg';
export const countryData = {
    usa: {
      name: 'United States',
      description: {
        part1: 'The United States is a country of 50 states covering a vast swath of North America...',
        part2: 'It has iconic landmarks like the Statue of Liberty, the Grand Canyon, and Times Square.',
        part3: 'Diverse landscapes from the mountains of Colorado to the beaches of California make it a prime destination.',
      },
      reasonsToVisit: [
        'Explore iconic cities like New York and Los Angeles.',
        'Visit national parks such as Yellowstone and the Grand Canyon.',
        'Experience diverse cultures and cuisines.',
      ],
      touringSpots: [
        { name: 'Statue of Liberty', description: 'An iconic symbol of freedom.' },
        { name: 'Grand Canyon', description: 'A natural wonder with breathtaking views.' },
        { name: 'Times Square', description: 'The bustling heart of New York City.' },
      ],
      image: usa, // Image path or URL
    },
    // Add other countries as needed
  };
  