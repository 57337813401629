import React from "react";

const Step6 = ({ formik }) => {
  return (
    <>
      <div className="md:flex md:flex-row flex-col justify-between border-b border-gray-200">
        <h1 className="text-2xl font-bold pb-4 text-gray-600">
          Choose Your Processing Time
        </h1>
      </div>

      {/* Processing Time Options */}
      <div className="mb-4">
        <label className="block text-gray-600 text-sm font-medium mb-2">
          Please select your preferred visa processing time:
        </label>
        <div className="flex flex-col">
          <label className="flex items-center cursor-pointer mb-2">
            <div
              className={`border-2 hover:border-2 hover:border-black text-center font-semibold rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
                formik.values.processingTime === "Standard"
                  ? "border-2 border-black"
                  : ""
              }`}
            >
              Standard Processing
              <input
                type="radio"
                className="hidden"
                name="processingTime"
                value="Standard"
                checked={formik.values.processingTime === "Standard"}
                onChange={formik.handleChange}
              />
            </div>
          </label>

          <label className="flex items-center cursor-pointer mb-2">
            <div
              className={`border-2 hover:border-2 hover:border-black text-center font-semibold rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
                formik.values.processingTime === "Expedited"
                  ? "border-2 border-black"
                  : ""
              }`}
            >
              Expedited Processing
              <input
                type="radio"
                className="hidden"
                name="processingTime"
                value="Expedited"
                checked={formik.values.processingTime === "Expedited"}
                onChange={formik.handleChange}
              />
            </div>
          </label>

          <label className="flex items-center cursor-pointer mb-2">
            <div
              className={`border-2 hover:border-2 hover:border-black text-center font-semibold rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
                formik.values.processingTime === "Super Expedited"
                  ? "border-2 border-black"
                  : ""
              }`}
            >
              Super Expedited Processing
              <input
                type="radio"
                className="hidden"
                name="processingTime"
                value="Super Expedited"
                checked={formik.values.processingTime === "Super Expedited"}
                onChange={formik.handleChange}
              />
            </div>
          </label>
        </div> 

        {/* Display validation error */}
        {formik.touched.processingTime && formik.errors.processingTime && (
          <p className="text-red-500 text-sm mt-1">
            {formik.errors.processingTime}
          </p>
        )}
      </div>

     
    </>
  );
};

export default Step6;
