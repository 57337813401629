import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Cookies from 'js-cookie'; // Assuming you use js-cookie for cookie handling

const useVisaApplicationStore = create(
  devtools((set) => ({
    visaApplication: null,
    visaApplications: [],
    singleVisaApplication: null,
    isLoading: false,
    error: null,
    discountDetails: null,

    // Create a new Visa Application
    createVisaApplication: async (visaData,token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.post('http://localhost:7000/api/visa', visaData,{
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set({
          visaApplication: response.data,
          isLoading: false,
          error: null, 
        });
        return response.data;
      } catch (error) {
        console.error('Error creating visa application:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        throw error;
      }
    },

    getVisaApplicationsByUserId: async (userId, token) => { // New function
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get(`http://localhost:7000/api/visa/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set({
          visaApplications: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching visa applications by user ID:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    applyCoupon: async (code, totalAmount,userId) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.post('http://localhost:7000/api/admins/coupon/apply-coupon', { code, totalAmount,userId});
        
        // Update the store with discount details
        set({
          discountDetails: {
            discountPercentage: response.data.discountPercentage,
            discountAmount: response.data.discountAmount,
            finalAmount: response.data.finalAmount,
            adminDetails: response.data.adminDetails,
          },
          isLoading: false,
          error: null,
        });
        
        return response.data; // Optionally return the response
      } catch (error) {
        console.error('Error applying coupon:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        return null;
      }
    },
    // Fetch all Visa Applications
    getVisaApplications: async (token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get('http://localhost:7000/api/visa', {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set({
          visaApplications: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error applying coupon:', error);
        
        // Check if the error is an Axios error and has a response
        const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
        
        set({
          error: errorMessage, // Set the error message in the store
          isLoading: false,
        });
    
        // Optionally log the error to an external service here
    
        // Do not throw the error, to prevent red screen errors
        return null; // Return null or any other value that indicates failure
      }
    },

    // Fetch a single Visa Application by ID
    getVisaApplicationById: async (visaId,token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get(`http://localhost:7000/api/visa/${visaId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set({
          singleVisaApplication: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching visa application by ID:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Update a Visa Application
    updateVisaApplication: async (visaId, updatedData) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.put(`http://localhost:7000/api/visa/${visaId}`, updatedData);
        set((state) => ({
          visaApplications: state.visaApplications.map((visa) =>
            visa._id === visaId ? { ...visa, ...response.data } : visa
          ),
          singleVisaApplication: response.data,
          isLoading: false,
          error: null,
        }));

        // Optionally update cookies with updated data
        Cookies.set('visaApplicationToken', response.data.token);
        
        return response.data;
      } catch (error) {
        console.error('Error updating visa application:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Delete a Visa Application
    deleteVisaApplication: async (visaId) => {
      try {
        set({ isLoading: true, error: null });
        await axios.delete(`http://localhost:7000/api/visa/${visaId}`);
        set((state) => ({
          visaApplications: state.visaApplications.filter((visa) => visa._id !== visaId),
          isLoading: false,
          error: null,
        }));

        // Optionally remove the associated cookies
        Cookies.remove('visaApplicationToken');
      } catch (error) {
        console.error('Error deleting visa application:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Reset visa application data (for clearing out the form, etc.)
    resetVisaApplication: () => {
      set({
        visaApplication: null,
        singleVisaApplication: null,
        isLoading: false,
        error: null,
      });

      // Optionally remove the cookie data on reset
      Cookies.remove('visaApplicationToken');
    },
  })) 
);

export default useVisaApplicationStore;
