import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import NotFound from './components/PageNotFound/NotFound';
import SignIn from './components/Signup/SignIn';
import SignUp from './components/Signup/SignUp';

import ContactUs from './components/ContactUs/ContactUs';
import CountiresDetail from './components/CountriesList/Detailpage/CountiresDetail';
import Dashboard from './components/Dashboard/Dashboard';
import Admindashboard from './components/AdminPanel/Admindashboard';
import AdminLogin from './components/AdminPanel/login/AdminLogin';
import OrderSummary from './components/Cart/OrderSummary';
import AboutUs from './components/AboutUs/AboutUs';
import {AuthProvider} from "./context/AuthContext";
import { AdminAuthProvider } from './context/AdminAuthProvider';
import PrivateRoutes from "./utils/PrivateRoutes";
import AdminPrivateRoute from "./utils/AdminPrivateRoute"; 
import Apply from './components/CountriesList/Apply/Apply';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PayPalScriptProvider } from "@paypal/react-paypal-js"; // Import PayPalScriptProvider

const initialOptions = {
  "client-id": "AY7ivmNa3ve75EIcQMI8YeX1Okq48yfstRSljM5nzcn-6MJL6JVDgGouITRmuwqxjteox0IisQ6n1vca", // Use environment variable for client ID
  currency: "USD",
  intent: "capture",
};

const router = (
  <BrowserRouter>
   
    <Routes>
      <Route path="/" element={<App />}>
      <Route index={true} element={<Home />} />
      <Route path="*" element ={<NotFound />} />
      <Route path="/apply" element={<Apply />} /> 
      <Route path="/country/:countryCode" element={<CountiresDetail />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/order-summary" element={<OrderSummary />} />
      <Route path="/login" element={<SignIn />}></Route>
      <Route path="/sign-up" element={<SignUp />}></Route>
      <Route path="/dashboard" element={<PrivateRoutes element={<Dashboard />} />}></Route>
      <Route path="/admin-dashboard" element={<AdminPrivateRoute element={<Admindashboard />} />} />
      <Route path="/admin-login" element={<AdminLogin />}></Route>
      
      </Route>
        </Routes>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <GoogleOAuthProvider clientId='710741479477-uie6vv0gq9qeiom8koqrm1ogd8qe8q8g.apps.googleusercontent.com'>
     <AuthProvider>
     <AdminAuthProvider>
   <PayPalScriptProvider options={initialOptions}> {/* Wrap with PayPalScriptProvider */}
          {router}
        </PayPalScriptProvider>
        </AdminAuthProvider>
  </AuthProvider>
  </GoogleOAuthProvider>
  </React.StrictMode>
);


