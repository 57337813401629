import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const secretKey = 'xZ1$yR7@Q%9k#F8*J2^gH4!T6&dP3*mN0$C5#jE8^B1'; // Same Secret Key

const useAdminStore = create(
  devtools((set) => ({
    admin: null,
    admins: [],
    singleAdmin: null,
    isLoading: false,
    isFetchingAdminData: false,
    isUpdatingAdmin: false,
    error: null,

    // Register a new admin
    registerAdmin: async (adminData,token) => { 
      try {
        set({ isLoading: true, error: null });
        const response = await axios.post('http://localhost:7000/api/admin', adminData, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        const { _id, firstname, lastname, email, role, department, contact } = response.data;

        // Encrypt and store token and admin details separately in cookies
        // const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
        // const encryptedAdminDetails = CryptoJS.AES.encrypt(JSON.stringify({ _id, firstname, lastname, email, role, department, contact }), secretKey).toString();

        // Cookies.set('adminAuthToken', encryptedToken, { expires: 1/24, secure: true, sameSite: 'Strict' });
        // Cookies.set('adminDetails', encryptedAdminDetails, { expires: 1/24, secure: true, sameSite: 'Strict' });

        set({
          admin: { _id, firstname, lastname, email, role, department, contact },
          isLoading: false,
          error: null,
        });

        return response.data;
      } catch (error) {
        console.error('Error registering admin:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        throw error;
      }
    },

    fetchAdminData: () => {
      try {
        set({ isFetchingAdminData: true });
        const encryptedToken = Cookies.get('adminAuthToken');
        const encryptedAdminDetails = Cookies.get('adminDetails');

        if (encryptedToken && encryptedAdminDetails) {
          const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, secretKey).toString(CryptoJS.enc.Utf8);
          const decryptedAdminDetails = JSON.parse(CryptoJS.AES.decrypt(encryptedAdminDetails, secretKey).toString(CryptoJS.enc.Utf8));

          set({ admin: decryptedAdminDetails, isFetchingAdminData: false });
          return { token: encryptedToken, adminDetails: decryptedAdminDetails };
        } else {
          set({ isFetchingAdminData: false });
          return null; // If no data is found
        }
      } catch (error) {
        set({ isFetchingAdminData: false });
        console.error('Error fetching admin data:', error);
        return null;
      }
    },
    // Login admin
    loginAdmin: async (credentials) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.post('http://localhost:7000/api/admin/login', credentials);
        const { token, _id, firstname, lastname, email, role, department, contact } = response.data;

        // Encrypt and store token and admin details separately in cookies
        const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
        const encryptedAdminDetails = CryptoJS.AES.encrypt(JSON.stringify({ _id, firstname, lastname, email, role, department, contact }), secretKey).toString();

        
        Cookies.set('adminAuthToken', token, { expires: 1/24, secure: true, sameSite: 'Strict' });
        Cookies.set('adminDetails', encryptedAdminDetails, { expires: 1/24, secure: true, sameSite: 'Strict' });

        set({
          admin: { _id, firstname, lastname, email, role, department, contact },
          isLoading: false,
          error: null,
        });

        return response.data;
      } catch (error) {
        console.error('Error logging in admin:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        throw error;
      }
    },

    // Fetch all admins
    getAdmins: async (token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get('http://localhost:7000/api/admin', {
            headers: {
                Authorization: `Bearer ${token}`, // Add the token to the Authorization header
            },
        });
        set({
          admins: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching admins:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Fetch a single admin by ID
    getAdminById: async (adminId) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get(`http://localhost:7000/api/admin/${adminId}`);
        set({
          singleAdmin: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching admin by ID:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Update admin details
    updateAdmin: async (adminId, updatedData, token) => {
      try {
        set({ isUpdatingAdmin: true, error: null });
        const response = await axios.put(`http://localhost:7000/api/admin/${adminId}`, updatedData, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set((state) => ({
          admins: state.admins.map((admin) =>
            admin._id === adminId ? { ...admin, ...response.data } : admin
          ),
          singleAdmin: response.data,
          isUpdatingAdmin: false,
          error: null,
        }));
        const encryptedAdminDetails = Cookies.get('adminDetails');
    const encryptedToken = Cookies.get('adminAuthToken');

    if (encryptedAdminDetails && encryptedToken) {
      // Decrypt the existing admin details
      const decryptedAdminDetails = JSON.parse(CryptoJS.AES.decrypt(encryptedAdminDetails, secretKey).toString(CryptoJS.enc.Utf8));

      // Update the decrypted details with new data
      const updatedAdminDetails = { ...decryptedAdminDetails, ...response.data };

      // Encrypt the updated admin details and set them back in cookies
      const encryptedUpdatedAdminDetails = CryptoJS.AES.encrypt(JSON.stringify(updatedAdminDetails), secretKey).toString();
      Cookies.set('adminDetails', encryptedUpdatedAdminDetails);
      if (response.data.token) {  // Make sure your API sends a new token if the role changes
        const newToken = response.data.token;
        const encryptedNewToken = CryptoJS.AES.encrypt(newToken, secretKey).toString();
        Cookies.set('adminAuthToken', newToken); // Store new token
      }

      // Optionally, you could also update the token if needed
      // const newToken = response.data.token; // If you receive a new token from the response
      // const encryptedNewToken = CryptoJS.AES.encrypt(newToken, secretKey).toString();
      // Cookies.set('adminAuthToken', encryptedNewToken); // Store new token if applicable
    }
        return response.data;
      } catch (error) {
        console.error('Error updating admin:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isUpdatingAdmin: false,
        });
      }
    },

    getAdminCoupons: async (token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get('http://localhost:7000/api/admins/coupon', {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token for authorization
          },
        });

        // Store the coupons in the state
        set({
          coupons: response.data,
          isLoading: false,
          error: null,
        });

        return response.data; // Return coupons if needed
      } catch (error) {
        console.error('Error fetching admin coupons:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        throw error; // Throw error if needed
      }
    },

    createCoupon: async (discountPercentage,token) => {
      try {
        set({ isLoading: true, error: null });
        
        const response = await axios.post('http://localhost:7000/api/admins/coupon', { discountPercentage }, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        }); 

        // On successful coupon creation, you can set the coupon in your store state
        const coupon = response.data;
        
        // Optionally, if you want to keep a list of coupons in the state:
        set((state) => ({
          coupons: [...(state.coupons || []), coupon], // Assuming you will also add a coupons state in the store
          isLoading: false,
          error: null,
        }));

        return coupon; // Return the created coupon
      } catch (error) {
        console.error('Error creating coupon:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },
    // Delete an admin
    deleteAdmin: async (adminId,token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.delete(`http://localhost:7000/api/admin/${adminId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        set((state) => ({
          admins: state.admins.filter((admin) => admin._id !== adminId),
          isLoading: false,
          error: null,
        }));
        return response;
      } catch (error) {
        console.error('Error deleting admin:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Logout
    logout: () => {
      Cookies.remove('adminAuthToken');
      Cookies.remove('adminDetails');
      set({ admin: null });
      window.location.reload(); // Optional: reload the page to reset the application state
    },
  }))
);

export default useAdminStore;
