import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import Cart from "../Cart/Cart";
import { useAuth } from "../../context/AuthContext";
import useUserStore from "../../Stores/userStore";
import useVisaStore from "../../Stores/visaStoreCookie";

const Navbar = () => {
  const { logout } = useUserStore();
  const { fetchFormData ,formData} = useVisaStore();
  const [visaFormLength, setVisaFormLength] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { authToken } = useAuth();
  const [cartOpen, setCartOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setAtTop(window.pageYOffset <= 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const getVisaForm = async () => {
      try {
        const visaForm = await fetchFormData();
        console.log("Visa form content:", visaForm);

        if (visaForm && Object.keys(visaForm).length > 0) {
          // If visaForm exists and has any content, set it to 1
          setVisaFormLength(1);
        } else {
          setVisaFormLength(0); // If no form data, set length to 0
        }
      } catch (error) {
        console.error("Error fetching visa form data:", error);
        setVisaFormLength(0); // In case of error, set length to 0
      }
    };

    getVisaForm();
  }, [fetchFormData]);

  return (
    <>
      <div className="h-24">
        <div
          className={`fixed z-50 w-full px-8 py-4 transition-all duration-1000 rounded-full mt-4 inset-x-0 mx-auto ease-in-out transform ${
            atTop
              ? "max-w-2xl"
              : "bg-black bg-opacity-90 backdrop-blur-xl max-w-4xl"
          }`}
        >
          <div className="flex flex-col w-full p-2 mx-auto md:items-center md:justify-between md:flex-row">
            <div className="flex flex-row items-center justify-between">
              <span
                className={`font-bold tracking-tighter uppercase ${
                  atTop ? "text-black" : "text-white"
                }`}
              >
                <Link to="/">
                  <img className="h-8 md:h-10" src={logo} alt="" />
                </Link>
              </span>
              <div className="flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className={`${atTop ? "text-black" : "text-white"}`}
                  aria-label="toggle menu"
                >
                  <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                    <path
                      fillRule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <nav
              className={`flex-col flex-grow gap-6 pb-4 md:pb-0 md:flex md:flex-row lg:ml-auto justify-end ${
                open ? "flex" : "hidden"
              }`}
            >
              <Link to="/" className={`${atTop ? "text-black" : "text-white"}`}>
                Home
              </Link>
              <Link
                className={`${atTop ? "text-black" : "text-white"}`}
                to="/contact"
              >
                Contact
              </Link>
              <Link
                to="/about-us"
                className={`${atTop ? "text-black" : "text-white"}`}
              >
                About
              </Link>
              <Link className={`${atTop ? "text-black" : "text-white"}`}>
                Shop
              </Link>
              {authToken ? (
                <>
                  <button
                    onClick={logout}
                    className={`  px-4 ${
                      atTop
                        ? "text-black border-2 rounded-md border-black"
                        : "text-white border-2 rounded-md border-white"
                    }`}
                  >
                    Logout
                  </button>
                </>
              ) : (
                <Link
                  to="/login"
                  className={`  px-4 ${
                    atTop
                      ? "text-black border-2 rounded-md border-black"
                      : "text-white border-2 rounded-md border-white"
                  }`}
                >
                  Login
                </Link>
              )}

              <button
                onClick={() => setIsModalOpen(true)}
                className={`  focus:outline-none mx-4 sm:mx-0 relative`}
              >
                <div className="absolute -top-1 right-0 z-5 bg-yellow-400 text-xs px-1 py-0.5 rounded-full">
                  {visaFormLength}
                </div>
                <svg
                  className={`h-9 lg:h-8 ${
                    atTop ? "text-black" : "text-white"
                  }`}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
      <nav
        className={` ${
          isOpen
            ? "bg-opacity-90 backdrop-blur-xl max-w-4xl fixed z-50 w-full px-8 py-4 transition-all duration-1000 inset-x-0 mx-auto ease-in-out transform sm:flex sm:justify-center sm:items-center"
            : "hidden"
        } md:hidden `}
      >
        <div className="flex flex-col sm:flex-row">
          <Link
            className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
            to="/"
          >
            Home
          </Link>

          <Link
            className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
            to="/shop"
          >
            Shop
          </Link>
          <Link
            className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
            to="/contact"
          >
            Contact
          </Link>
          <Link
            to="/about-us"
            className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
          >
            About
          </Link>
          {authToken ? (
            <>
              <button
                onClick={logout}
                className="mt-3 text-black bg-white hover:underline sm:mx-3 sm:mt-0 border border-black rounded-md"
              >
                Logout
              </button>
              <Link
                to="/dashboard"
                className="mt-3 text-black bg-white hover:underline sm:mx-3 sm:mt-0 border border-black rounded-md text-center"
              >
                Dashboard
              </Link>
            </>
          ) : (
            <Link
              to="/login"
              className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
            >
              Login
            </Link>
          )}

          <button
            className="mt-3 text-black bg-white hover:underline sm:mx-3 sm:mt-0 border border-black rounded-md"
            onClick={() => setIsModalOpen(true)}
          >
            Cart
          </button>
        </div>
      </nav>
      <Cart open={isModalOpen} onClose={() => setIsModalOpen(false)} formData={formData} />
    </>
  );
};

export default Navbar;
