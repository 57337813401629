    import React from 'react';
    import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
    import useVisaStore from '../../../../Stores/visaStoreCookie';

    const Paypal = ({ currency, onCurrencyChange,onPaymentSuccess,price }) => {
        const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
        const updateFormData = useVisaStore((state) => state.updateFormData);
        const onCreateOrder = (data, actions) => {
            return actions.order.create({
            purchase_units: [
                {
                amount: {
                    value: price, // Replace with your actual amount
                }, 
                },
            ],
            });
        };
        
        const onApproveOrder = (data, actions) => {
            return actions.order.capture().then((details) => {
            console.log("Transaction details:", details);
            const name = details.payer.name.given_name;
            const st = details.status;
            alert(`Transaction completed by ${name} ${st}`);
            if(st === 'COMPLETED'){
            onPaymentSuccess(true);
            const paymentData = {
                transactionId: details.id,
                payerInfo: {
                    id: details.payer.payer_id,
                    name: name,
                    email: details.payer.email_address,
                },
                status: details.status,
                amount: details.purchase_units[0].amount.value,
                currency: details.purchase_units[0].amount.currency_code,
            };

            updateFormData('paymentDetails', paymentData);
        }
            });
        };
        
    return (
        <>
        <div className="mt-6">
        {isPending ? (
            <p>Loading...</p>
        ) : (
            <>
            <select value={currency} onChange={onCurrencyChange}>
                <option value="USD">💵 USD</option>
                <option value="EUR">💶 Euro</option>
                {/* Add more currencies as needed */}
            </select>
            <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={onCreateOrder}
                onApprove={onApproveOrder}
            />
            </>
        )}
        </div>
        </>
    )
    }

    export default Paypal