import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const useCountryStore = create(
  devtools((set) => ({
    country: null,
    countries: [],
    singleCountry: null,
    isLoading: false,
    isFetchingCountryData: false,
    isUpdatingCountry: false,
    error: null,

    // Add a new country
    addCountry: async (countryData, token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.post('http://localhost:7000/api/countries', countryData, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        }); 
        set((state) => ({
          countries: [...state.countries, response.data], // Add the new country to the existing list
          isLoading: false,
          error: null,
        }));
        return response.data;
      } catch (error) {
        console.error('Error adding country:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        throw error;
      }
    },

    // Fetch all countries
    getCountries: async (token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get('http://localhost:7000/api/countries');
        set({
          countries: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching countries:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      } 
    },

    // Fetch a single country by ID
    getCountryById: async (countryId, token) => { 
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get(`http://localhost:7000/api/countries/${countryId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set({
          singleCountry: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching country by ID:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Update country details
    updateCountry: async (countryId, updatedData, token) => {
      try {
        set({ isUpdatingCountry: true, error: null });
        const response = await axios.put(`http://localhost:7000/api/countries/${countryId}`, updatedData, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set((state) => ({
          countries: state.countries.map((country) =>
            country._id === countryId ? { ...country, ...response.data } : country
          ),
          singleCountry: response.data,
          isUpdatingCountry: false,
          error: null,
        }));
        return response.data;
      } catch (error) {
        console.error('Error updating country:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isUpdatingCountry: false,
        });
      }
    },

    // Delete a country
    deleteCountry: async (countryId, token) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.delete(`http://localhost:7000/api/countries/${countryId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set((state) => ({
          countries: state.countries.filter((country) => country._id !== countryId),
          isLoading: false,
          error: null,
        }));
        return response;
      } catch (error) {
        console.error('Error deleting country:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

   
  }))
);

export default useCountryStore;
