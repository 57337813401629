import React, { useEffect, useState } from "react";
import useAdminStore from "../../../Stores/adminStore";
import { FaTrash, FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyCoupon = () => {
  const {
    fetchAdminData,
    getAdminCoupons,
    coupons,
    isLoading,
    isFetchingAdminData,
    error,
  } = useAdminStore();
  const [data, setData] = useState([]);
  const [tokenfup, setTokenfup] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const fetchUser = async () => {
      const adminData = await fetchAdminData();
      if (adminData) {
        setTokenfup(adminData.token);
      }
    };
    fetchUser();
  }, [fetchAdminData]);
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const res = await getAdminCoupons(tokenfup);
        if (res) {
          setData(res);
          
        }
      } catch (error) {
        // Handle error logging
      }
    };

    if (tokenfup) {
      
      fetchAdmins();
    }
  }, [getAdminCoupons, tokenfup]);

  useEffect(() => {
    
    let updatedData = data;

    if (searchTerm) {
      updatedData = updatedData.filter((coupon) =>
        coupon.code.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredData(updatedData);
  }, [data, searchTerm]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }, [error]);

  const calculateRemainingDays = (expiresAt) => {
    const expiryDate = new Date(expiresAt);
    const currentDate = new Date();
    const differenceInMs = expiryDate - currentDate;
    const remainingDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    return remainingDays < 0 ? 0 : remainingDays;
  };
  return (
    <>
      {isLoading || isFetchingAdminData ? (
        <div className="flex justify-center items-center min-h-screen">
          <div className="relative">
            <div className="w-20 h-20 border-purple-200 border-2 rounded-full"></div>
            <div className="w-20 h-20 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
          </div>
        </div>
      ) : (
        <>
          <ToastContainer />
          <section className="container px-6 py-2 mx-auto">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div>
                <div className="flex items-center gap-x-3">
                  <h2 className="text-lg font-medium text-gray-800 ">
                    Cpupons
                  </h2>

                  <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">
                    {data && data.length > 0 ? data.length : 0} Coupons
                  </span>
                </div>

                <p className="mt-1 text-sm text-gray-500 ">
                  These Are the list of the all the Coupon code
                </p>
              </div>
            </div>

            <div className="mt-6 md:flex md:items-center md:justify-between">
              <div className="relative flex items-center mt-4 md:mt-0">
                <input
                  type="text"
                  placeholder="Search by Name"
                  className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-3 focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-col mt-6">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden border border-gray-200 ">
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            <span>Code</span>
                          </th>

                          <th
                            scope="col"
                            className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            Expires at
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                          >
                            Discount Percentage
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 ">
                        {currentItems.map((coupoon, index) => (
                          <tr key={index}>
                            <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                              <div>
                                <h2 className="font-medium text-gray-800  ">
                                  {coupoon.code}
                                </h2>
                              </div>
                            </td>
                            <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
  {calculateRemainingDays(coupoon.expiresAt) > 0 ? (
    <div className="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60">
      {new Date(coupoon.expiresAt).toLocaleDateString()}{" "}
      Days remaining{" "}
      {calculateRemainingDays(coupoon.expiresAt)}
    </div>
  ) : (
    <div className="inline px-3 py-1 text-sm font-normal rounded-full text-red-500 gap-x-2 bg-red-100/60">
      Expired
    </div>
  )}
</td>

                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div>
                                <h4 className="text-gray-700 ">
                                  {coupoon.discountPercentage}%
                                </h4>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
              <div className="text-sm text-gray-500 ">
                Page{" "}
                <span className="font-medium text-gray-700 ">
                  {currentPage} of {totalPages}
                </span>
              </div>

              <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100"
                  disabled={currentPage === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 rtl:-scale-x-100"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                    />
                  </svg>
                  <span>Previous</span>
                </button>

                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100"
                  disabled={currentPage === totalPages}
                >
                  <span>Next</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 rtl:-scale-x-100"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MyCoupon;
