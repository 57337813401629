import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useUserStore from "../../../Stores/userStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateProfile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const { fetchUserData, updateUser, isFetchingUserData, isUpdatingUser, error } = useUserStore();
  const [tokenfup, setTokenfup] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userData = await fetchUserData();  // Await the user data fetching
      if (userData) {
        setUserDetails(userData.userDetails);
        setTokenfup(userData.token);
      }
    };
    fetchUser();
  }, [fetchUserData]);
  const formik = useFormik({
    initialValues: {
      firstname: userDetails?.firstname || "",
      lastname: userDetails?.lastname || "",
      email: userDetails?.email || "",
      password: "",
      city: userDetails?.city || "",
      contact: userDetails?.contact || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .required("First name is required")
        .matches(
          /^[a-zA-Z\s]+$/,
          "First name should only contain letters and spaces"
        ),
      lastname: Yup.string()
        .required("Last name is required")
        .matches(
          /^[a-zA-Z\s]+$/,
          "Last name should only contain letters and spaces"
        ),
      email: Yup.string()
        .required("Email is required")
        .email("Please provide a valid email address"),
      password: Yup.string()
      .min(6, "Password should be at least 6 characters long"),
      city: Yup.string(),
      contact: Yup.string().matches(
        /^[0-9]+$/,
        "Contact number should only contain numbers"
      ),
    }),
    onSubmit: async (values) => {
      try {
        const res = await updateUser(userDetails._id, values, tokenfup);
        if (res) {
          toast.success("User Updated successful!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.error("error Updating", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } 
      } catch (error) {
        console.error("Error updating user:", error);
       
      }
    },
  });

  useEffect(() => {
    if (userDetails) {
      formik.setValues({
        firstname: userDetails.firstname || "",
        lastname: userDetails.lastname || "",
        email: userDetails.email || "",
        password: "", 
        city: userDetails.city || "",
        contact: userDetails.contact || "",
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }, [error]);

  return (
    <>
    {isFetchingUserData ? (
      <div className="flex justify-center items-center min-h-screen">
      <div className="relative">
        <div className="w-20 h-20 border-purple-200 border-2 rounded-full"></div>
        <div className="w-20 h-20 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
      </div>
  
      <div className="relative mx-4">
        <div className="w-10 h-10 border-purple-200 border-2 rounded-full"></div>
        <div className="w-10 h-10 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
      </div>
  
      <div className="relative">
        <div className="w-5 h-5 border-purple-200 border-2 rounded-full"></div>
        <div className="w-5 h-5 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
      </div>
    </div>
    ):(
      <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Update Profile
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>

          <div className="mt-6 grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="firstname"
                className="block text-sm font-medium text-gray-700"
              >
                First Name:
              </label>
              <input
                type="text"
                id="firstname"
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.firstname && formik.errors.firstname
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.firstname}
                </div>
              ) : null}
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name:
              </label>
              <input
                type="text"
                id="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.lastname && formik.errors.lastname
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.lastname}
                </div>
              ) : null}
            </div>

            <div className="col-span-12">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email:
              </label>
              <input
                type="email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.email && formik.errors.email
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password:
              </label>
              <input
                type="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.password && formik.errors.password
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.password}
                </div>
              ) : null}
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City:
              </label>
              <input
                type="text"
                id="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.city && formik.errors.city
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="text-red-500 text-sm">{formik.errors.city}</div>
              ) : null}
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="contact"
                className="block text-sm font-medium text-gray-700"
              >
                Contact:
              </label>
              <input
                type="text"
                id="contact"
                value={formik.values.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none ${
                  formik.touched.contact && formik.errors.contact
                    ? "border-red-500"
                    : "border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                } sm:text-sm`}
              />
              {formik.touched.contact && formik.errors.contact ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.contact}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="divide-y divide-gray-200 pt-6">
          <div className="mt-4 flex justify-end py-4 px-4 sm:px-6">
            <button
              type="submit"
              className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-sky-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            >
              {isUpdatingUser ? "Updating..." : "Update Profile"}{" "}
              {/* Change button text */}
            </button>
          </div>
        </div>
      </form>
      </>
    )}
      
    </>
  );
};

export default UpdateProfile;
