// validationSchemas.js
import * as Yup from 'yup';

export const validationSchema1 = Yup.object({
  nationality: Yup.string().required("Nationality is required"),
  applyingFor: Yup.string().required("Applying country is required"),
});

export const validationSchema2 = Yup.object({
  arrivedate: Yup.string().required("Arriving Date is required"),
  leavedate: Yup.string()
    .required("Leaving Date is required")
    .test("is-greater", "Leaving date must be after arriving date", function(value) {
      const { arrivedate } = this.parent; // Access the parent object
      // Check if both dates are present and in valid format
      if (arrivedate && value) {
        return new Date(value) >= new Date(arrivedate);
      }
      return true; // If not both dates are present, skip validation
    }),
  email: Yup.string().email("Invalid email format").required("Email is required"),
});

export const validationSchema3 = Yup.object({
  travelers: Yup.array()
    .of(
      Yup.object().shape({
        fname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Invalid characters in first name")
          .required("First name is required"),
        lastname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Invalid characters in last name")
          .required("Last name is required"),
        dob: Yup.string().required("Date of Birth is required"),
      })
    )
    .required("At least one traveler is required")
    .min(1, "At least one traveler is required"),
});

export const validationSchema4 = Yup.object({
  travelers: Yup.array()
    .of(
      Yup.object().shape({
        citizenship: Yup.string().required("Citizenship is required"),
        birthCountry: Yup.string().required("Country of birth is required"),
        residenceCountry: Yup.string().required("Country of residence is required"),
        gender: Yup.string().required("Gender is required"),
        passportNumber: Yup.string().required("Passport number is required")
          .matches(/^[A-Za-z0-9]+$/, "Passport number is invalid"),
        passportIssuance: Yup.date().required("Date of issuance is required"),
        passportExpiry: Yup.date()
          .required("Date of expiry is required")
          .test("is-greater", "Expiry date must be after issuance date", function(value) {
            const { passportIssuance } = this.parent; // Access the parent object
            // Check if both dates are present
            if (passportIssuance && value) {
              return new Date(value) > new Date(passportIssuance); // Ensure expiry is after issuance
            }
            return true; // Skip validation if one of the dates is not present
          }),
        occupation: Yup.string().required("Occupation status is required"), 
        income: Yup.string().required("Income after tax is required"),
        assets: Yup.string().required("This field is required"),
        travelHistory: Yup.string().required("Travel history is required"),
        visaApplication: Yup.string().required("Visa application history is required"),
      })
    )
    .required("At least one traveler is required")
    .min(1, "At least one traveler is required"),
});

export const validationSchema5 = Yup.object({
  travelers: Yup.array()
    .of(
      Yup.object().shape({
        appointment: Yup.string().required("Appointment Location is required"),
      })
    )
    .required("At least one traveler is required")
    .min(1, "At least one traveler is required"),
});

export const validationSchema6 = Yup.object({
  processingTime: Yup.string().required("Processing time is required"),
});

export const validationSchema7 = Yup.object({
  paymentMethod: Yup.string().required("Payment method is required"),
  
});

const fileSize = (size) => {
  return Yup.mixed().test('fileSize', 'File too large', value => {
    return value && value.size <= size; // size in bytes
  });
};

const fileType = (type) => {
  return Yup.mixed().test('fileType', 'Unsupported file format', value => {
    return value && type.includes(value.type);
  });
};

export const validationSchema8 = Yup.object({
  cnic: Yup.mixed()
    .required("CNIC is required")
    .test('fileType', 'Unsupported File Format', value => {
      return value && fileType(['application/pdf', 'image/jpeg', 'image/png']).isValidSync(value);
    })
    .test('fileSize', 'File too large', value => {
      return value && fileSize(2 * 1024 * 1024).isValidSync(value); // 2MB limit
    }),
  resume: Yup.mixed()
    .required("Resume is required")
    .test('fileType', 'Unsupported File Format', value => {
      return value && fileType(['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']).isValidSync(value);
    })
    .test('fileSize', 'File too large', value => {
      return value && fileSize(2 * 1024 * 1024).isValidSync(value); // 2MB limit
    }),
  passport: Yup.mixed()
    .required("Passport is required")
    .test('fileType', 'Unsupported File Format', value => {
      return value && fileType(['application/pdf', 'image/jpeg', 'image/png']).isValidSync(value);
    })
    .test('fileSize', 'File too large', value => {
      return value && fileSize(2 * 1024 * 1024).isValidSync(value); // 2MB limit
    }),
});

// Export an array if needed
export const validationSchemas = [
  validationSchema1,
  validationSchema2,
  validationSchema3,
  validationSchema4,
  validationSchema5,
  validationSchema6,
  validationSchema7,
  validationSchema8
];
