import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
const secretKey = 'a1d5c9f2e4b78903c4d2f1e0b3a9c8f0abcdef1234567890abcdef0987654321'; // New Secret Key


const useUserStore = create(
  devtools((set) => ({
    user: null,
    users: [],
    singleUser: null,
    isLoading: false,
    isFetchingUserData: false, // Loading state for fetching user data
  isUpdatingUser: false,
    error: null,
    
    // Register a new user 
    registerUser: async (userData) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.post('http://localhost:7000/api/users', userData);
        const { token, _id, firstname, lastname, email,city,contact } = response.data;

        // Encrypt and store token and user details separately in cookies
        const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
        const encryptedUserDetails = CryptoJS.AES.encrypt(JSON.stringify({ _id, firstname, lastname, email }), secretKey).toString();

        Cookies.set('authToken', encryptedToken, { expires: 7, secure: true, sameSite: 'Strict' });
        Cookies.set('userDetails', encryptedUserDetails, { expires: 7, secure: true, sameSite: 'Strict' });
        set({
          user: { _id, firstname, lastname, email,city,contact },
          isLoading: false,
          error: null,
        });
        
        return response.data;
      } catch (error) {
        console.error('Error registering user:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        throw error;
      }
    },
    
    // Login user
    loginUser: async (credentials) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.post('http://localhost:7000/api/users/login', credentials);
        const { token, _id, firstname, lastname, email,city,contact } = response.data;

        // Encrypt and store token and user details separately in cookies
        const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
        const encryptedUserDetails = CryptoJS.AES.encrypt(JSON.stringify({ _id, firstname, lastname, email,city,contact }), secretKey).toString();

        Cookies.set('authToken', encryptedToken, { expires: 7, secure: true, sameSite: 'Strict' });
        Cookies.set('userDetails', encryptedUserDetails, { expires: 7, secure: true, sameSite: 'Strict' });

        set({
          user: { _id, firstname, lastname, email,city,contact },
          isLoading: false,
          error: null,
        });
        
        return response.data;
      } catch (error) {
        console.error('Error logging in:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        console.log(error)
        throw error;
      }
    },
    
    fetchUserData: () => {
      try {
        set({ isFetchingUserData: true });
        const encryptedToken = Cookies.get('authToken');
        const encryptedUserDetails = Cookies.get('userDetails');

        if (encryptedToken && encryptedUserDetails) {
          const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, secretKey).toString(CryptoJS.enc.Utf8);
          const decryptedUserDetails = JSON.parse(CryptoJS.AES.decrypt(encryptedUserDetails, secretKey).toString(CryptoJS.enc.Utf8));

          set({ user: decryptedUserDetails,isFetchingUserData: false});
          return { token: encryptedToken, userDetails: decryptedUserDetails };
        }
        else {
          set({ isFetchingUserData: false }); // If no data is found, stop loading
          return null;
        }
      } catch (error) {
        set({ isFetchingUserData: false });
        console.error('Error fetching user data:', error);
        return null;
      }
    },

    // Fetch all users
    getUsers: async () => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get('http://localhost:7000/api/users');
        set({
          users: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Fetch a single user by ID
    getUserById: async (userId) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.get(`http://localhost:7000/api/users/${userId}`);
        set({
          singleUser: response.data,
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching user by ID:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    },

    // Update user details
    updateUser: async (userId, updatedData,token) => {
      try {
        set({ isUpdatingUser: true, error: null });
        const response = await axios.put(`http://localhost:7000/api/users/${userId}`, updatedData, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        });
        set((state) => ({
          users: state.users.map((user) =>
            user._id === userId ? { ...user, ...response.data } : user
          ),
          singleUser: response.data,
          isUpdatingUser: false,
          error: null,
        }));
        return response.data;
      } catch (error) {
        console.error('Error updating user:', error);
        if (error.response?.status === 401) {
          useUserStore.getState().logout();  // Call the logout function
        }
    
        
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isUpdatingUser: false,
        });
      }
    },

    // Delete a user
    deleteUser: async (userId) => {
      try {
        set({ isLoading: true, error: null });
        await axios.delete(`http://localhost:7000/api/users/${userId}`);
        set((state) => ({
          users: state.users.filter((user) => user._id !== userId),
          isLoading: false,
          error: null,
        }));  
      } catch (error) {
        console.error('Error deleting user:', error); 
        set({ 
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
      }
    }, 

    // Signup with Google
    signupWithGoogle: async (userData) => {
      try {
        set({ isLoading: true, error: null });
        const response = await axios.post('http://localhost:7000/api/users/google', userData);
        const { token, _id, firstname, lastname, email } = response.data;

        // Encrypt the token and user details separately before storing
        const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
        const encryptedUserDetails = CryptoJS.AES.encrypt(JSON.stringify({ _id, firstname, lastname, email }), secretKey).toString();
    
        // Store the encrypted data in cookies
        Cookies.set('authToken', encryptedToken, { expires: 7, secure: true, sameSite: 'Strict' });
        Cookies.set('userDetails', encryptedUserDetails, { expires: 7, secure: true, sameSite: 'Strict' });
    
        // Update state with the user data
        set({
          user: { _id, firstname, lastname, email }, // Set user data directly
          isLoading: false,
          error: null,
        });
        return response.data;
      } catch (error) {
        console.error('Error signing up with Google:', error);
        set({
          error: error.response?.data?.message || error.message || 'An error occurred',
          isLoading: false,
        });
        throw error;
      }
    },

    // Logout
    logout: () => {
      Cookies.remove('authToken');
      Cookies.remove('userDetails');
      set({ user: null });
      window.location.reload(); // Optional: reload the page to reset the application state
    },
  }))
);

export default useUserStore;
