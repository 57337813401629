// src/store/useVisaStore.js
import { create } from 'zustand';
import Cookies from 'js-cookie';
import axios from 'axios';
import { devtools } from 'zustand/middleware';
import CryptoJS from 'crypto-js';
const secretKey = 'f9e8d7c6b5a43210fedcba9876543210abcdef0987654321abcdef1234567890';

const useVisaStore = create(
  devtools((set) => ({
    formData: {}, // Dynamic form fields
    ipAddress: '',
    currentStep: 1, // For multi-step form 
    loading: false, // Loading state

    // Initialize form data from cookies
    initializeForm: async () => {
      set({ loading: true }); // Set loading to true when starting the initialization
      try {
        // Load form data from cookies if available 
        const savedData = Cookies.get('visaFormData');  
        const savedStep = Cookies.get('currentStep'); 
        
        if (savedData) {
          const bytes = CryptoJS.AES.decrypt(savedData, secretKey);
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          set({ formData: decryptedData });
        }

        // Check saved step from cookies
        let stepValue = 1; // Default step
        if (savedStep) {
          stepValue = parseInt(savedStep, 10);
          if (isNaN(stepValue) || stepValue < 1 || stepValue > 8) {
            stepValue = 1; // Fallback to 1 if not valid
          }
        }
        set({ currentStep: stepValue }); // Set currentStep based on cookie

        // If no saved data, fetch and set IP address
        if (!savedData) {  
          const response = await axios.get('https://api.ipify.org?format=json');
          const ip = response.data.ip;
          set({ ipAddress: ip });

          // Update cookies with IP address
          const updatedData = { ipAddress: ip }; // Initialize with only the IP
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(updatedData), secretKey).toString();
          Cookies.set('visaFormData', encryptedData, { expires: 7, secure: true, sameSite: 'Strict' });
          set({ formData: updatedData });
        }
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        set({ loading: false }); // Set loading to false once initialization is complete
      }
    },

    fetchFormData: () => {
      const savedData = Cookies.get("visaFormData");
  
      if (savedData) {
        const bytes = CryptoJS.AES.decrypt(savedData, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        set({ formData: decryptedData });
        return decryptedData;
      } 
    },
    // Update form data dynamically
    updateFormData: (field, value,paymentData = null) => {
      set((state) => {
        const updatedFormData = { ...state.formData, [field]: value };
        if (paymentData) {
          Object.assign(updatedFormData, paymentData);
        }
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(updatedFormData), secretKey).toString();
        Cookies.set('visaFormData', encryptedData, { expires: 7, secure: true, sameSite: 'Strict' });

        return { formData: updatedFormData };
      }); 
    },

    // Set multiple form fields at once (useful for Formik's setValues)
    setMultipleFormData: (data) => {
      set((state) => {
        const updatedFormData = { ...state.formData, ...data };
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(updatedFormData), secretKey).toString();
     
        Cookies.set('visaFormData', encryptedData, { expires: 7, secure: true, sameSite: 'Strict' });
    
        return { formData: updatedFormData };
      }); 
    },

    // Clear form data and cookies
    clearFormData: () => { 
      set({ formData: {}, currentStep: 1 });
      Cookies.remove('visaFormData');
      Cookies.remove('currentStep');
       // Remove step from cookies
      
    }, 

    // Navigation for multi-step form
    nextStep: () => set((state) => {
      const nextStep = Math.min(state.currentStep + 1, 8); // Ensure max step is 5
      Cookies.set('currentStep', nextStep, { expires: 7 }); // Save step in cookies
      return { currentStep: nextStep };
    }),

    prevStep: () => set((state) => {
      const prevStep = Math.max(1, state.currentStep - 1);
      Cookies.set('currentStep', prevStep, { expires: 7 }); // Save step in cookies
      return { currentStep: prevStep };
    }),

    // Define setStep to allow manual setting of current step
    setStep: (step) => {
      const stepValue = parseInt(step, 10);
      if (!isNaN(stepValue) && stepValue >= 1 && stepValue <= 8) {
        set(() => {
          Cookies.set('currentStep', stepValue, { expires: 7 }); // Save step in cookies
          return { currentStep: stepValue };
        });
      }
    },
  }))
);

export default useVisaStore;
