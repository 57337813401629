import React from "react";
import { FaTrash } from "react-icons/fa"; // Import FaTrash

const Step4 = ({
  formik,
  handleTravelerChange,
  removeTraveler,
}) => {
  return (
    <>
      {formik.values.travelers.map((traveler, index) => (
        <div key={index}>
          <div className="md:flex md:flex-row flex-col justify-between border-b border-gray-200">
            <h1 className="text-2xl font-bold pb-4 text-gray-600">
              {traveler.fname} - Passport Information
            </h1>

            <button type="button" onClick={() => removeTraveler(index)}>
              <FaTrash className="h-8 w-8 text-black mb-2 md:md-0" />
            </button>
          </div>

          {/* Nationality on Passport */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Nationality on passport
            </label>
            <select
              className={`block w-full p-4 border rounded-lg ${
                formik.touched.travelers &&
                formik.touched.travelers[index]?.citizenship &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.citizenship
                  ? "border-red-500"
                  : ""
              }`}
              value={traveler.citizenship} 
              onChange={(e) =>
                handleTravelerChange(index, "citizenship", e.target.value)
              }
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select your citizenship" />
              <option value="PK" label="Pakistan - PK" />
              <option value="AF" label="Afghanistan - AF" />
              {/* Add more options as needed */}
            </select>
            <span className="text-red-500 text-sm mt-1">
              {formik.touched.travelers &&
                formik.touched.travelers[index]?.citizenship &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.citizenship}
            </span>
          </div>

          {/* Country of Birth */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Country of birth
            </label>
            <select
              className={`block w-full p-4 border rounded-lg ${
                formik.touched.travelers &&
                formik.touched.travelers[index]?.birthCountry &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.birthCountry
                  ? "border-red-500"
                  : ""
              }`}
              value={traveler.birthCountry}
              onChange={(e) =>
                handleTravelerChange(index, "birthCountry", e.target.value)
              }
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select your country of birth" />
              <option value="PK" label="Pakistan - PK" />
              <option value="AF" label="Afghanistan - AF" />
              {/* Add more options */}
            </select>
            <span className="text-red-500 text-sm mt-1">
              {formik.touched.travelers &&
                formik.touched.travelers[index]?.birthCountry &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.birthCountry}
            </span>
          </div>

          {/* Country of Residence */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Country of residence
            </label>
            <select
              className={`block w-full p-4 border rounded-lg ${
                formik.touched.travelers &&
                formik.touched.travelers[index]?.residenceCountry &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.residenceCountry
                  ? "border-red-500"
                  : ""
              }`}
              value={traveler.residenceCountry}
              onChange={(e) =>
                handleTravelerChange(index, "residenceCountry", e.target.value)
              }
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select your country of residence" />
              <option value="PK" label="Pakistan - PK" />
              <option value="AF" label="Afghanistan - AF" />
              {/* Add more options */}
            </select>
            <span className="text-red-500 text-sm mt-1">
              {formik.touched.travelers &&
                formik.touched.travelers[index]?.residenceCountry &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.residenceCountry}
            </span>
          </div>

          {/* Gender */}
          <div className="mb-4">
  <label className="block text-gray-600 text-sm font-medium mb-2">
    Gender
  </label>
  <div className="flex flex-col md:flex-row space-y-4 md:space-y-0">
    <label className="flex items-center cursor-pointer md:w-1/2">
      <div
        className={`border rounded-lg p-4 cursor-pointer flex-1 ${
          traveler.gender === "male" ? "border-blue-500" : ""
        }`}
      >
        Male
        <input
          type="radio"
          className="hidden"
          name={`travelers[${index}].gender`}
          value="male"
          checked={traveler.gender === "male"}
          onChange={(e) =>
            handleTravelerChange(index, "gender", e.target.value)
          }
        />
      </div>
    </label>
    <label className="flex items-center cursor-pointer md:w-1/2 md:ml-4">
      <div
        className={`border rounded-lg p-4 cursor-pointer flex-1 ${
          traveler.gender === "female" ? "border-blue-500" : ""
        }`}
      >
        Female
        <input
          type="radio"
          className="hidden"
          name={`travelers[${index}].gender`}
          value="female"
          checked={traveler.gender === "female"}
          onChange={(e) =>
            handleTravelerChange(index, "gender", e.target.value)
          }
        />
      </div>
    </label>
  </div>
  {formik.touched.travelers && 
    formik.touched.travelers[index]?.gender &&
    formik.errors.travelers && 
    formik.errors.travelers[index]?.gender && (
      <span className="text-red-500 text-sm mt-1">
        {formik.errors.travelers[index].gender}
      </span>
    )}
</div>


          {/* Passport Number */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Passport Number
            </label>
            <input
              type="text"
              className={`block w-full p-4 border rounded-lg ${
                formik.touched.travelers &&
                formik.touched.travelers[index]?.passportNumber &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.passportNumber
                  ? "border-red-500"
                  : ""
              }`}
              value={traveler.passportNumber}
              onChange={(e) =>
                handleTravelerChange(index, "passportNumber", e.target.value)
              }
              onBlur={formik.handleBlur}
            />
            <span className="text-red-500 text-sm mt-1">
              {formik.touched.travelers &&
                formik.touched.travelers[index]?.passportNumber &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.passportNumber}
            </span>
          </div>

          {/* Date of Issuance of Passport */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Date of issuance of passport
            </label>
            <input
              type="date"
              className={`block w-full p-4 border rounded-lg ${
                formik.touched.travelers &&
                formik.touched.travelers[index]?.passportIssuance &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.passportIssuance
                  ? "border-red-500"
                  : ""
              }`}
              value={traveler.passportIssuance}
              onChange={(e) =>
                handleTravelerChange(index, "passportIssuance", e.target.value)
              }
              onBlur={formik.handleBlur}
            />
            <span className="text-red-500 text-sm mt-1">
              {formik.touched.travelers &&
                formik.touched.travelers[index]?.passportIssuance &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.passportIssuance}
            </span>
          </div>

          {/* Date of Expiry of Passport */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Date of expiry of passport
            </label>
            <input
              type="date"
              className={`block w-full p-4 border rounded-lg ${
                formik.touched.travelers &&
                formik.touched.travelers[index]?.passportExpiry &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.passportExpiry
                  ? "border-red-500"
                  : ""
              }`}
              value={traveler.passportExpiry}
              onChange={(e) =>
                handleTravelerChange(index, "passportExpiry", e.target.value)
              }
              onBlur={formik.handleBlur}
            />
            <span className="text-red-500 text-sm mt-1">
              {formik.touched.travelers &&
                formik.touched.travelers[index]?.passportExpiry &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.passportExpiry}
            </span>
          </div>

          {/* Current occupation status */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Current occupation status
            </label>
            <select
              className={`block w-full p-4 border rounded-lg ${
                formik.touched.travelers &&
                formik.touched.travelers[index]?.occupation &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.occupation
                  ? "border-red-500"
                  : ""
              }`}
              value={traveler.occupation}
              onChange={(e) =>
                handleTravelerChange(index, "occupation", e.target.value)
              }
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select your current occupation" />
              <option value="employed" label="Employed" />
              <option value="unemployed" label="Unemployed" />
              <option value="student" label="Student" />
              <option value="retired" label="Retired" />
              {/* Add more options as needed */}
            </select>
            <span className="text-red-500 text-sm mt-1">
              {formik.touched.travelers &&
                formik.touched.travelers[index]?.occupation &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.occupation}
            </span>
          </div>

          {/* Current income after tax */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              What's your current income after tax?
            </label>
            <select
              className={`block w-full p-4 border rounded-lg ${
                formik.touched.travelers &&
                formik.touched.travelers[index]?.income &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.income
                  ? "border-red-500"
                  : ""
              }`}
              value={traveler.income}
              onChange={(e) =>
                handleTravelerChange(index, "income", e.target.value)
              }
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select your income range" />
              <option value="no_income" label="No income" />
              <option value="less_20000" label="Less than 20,000" />
              <option value="more_50000" label="More than 50,000" />
              {/* Add more options as needed */}
            </select>
            <span className="text-red-500 text-sm mt-1">
              {formik.touched.travelers &&
                formik.touched.travelers[index]?.income &&
                formik.errors.travelers &&
                formik.errors.travelers[index]?.income}
            </span>
          </div>

          {/* Do you own assets in your home country? */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Do you own assets in your home country?
            </label>
            <div className="flex">
              <label className="flex items-center cursor-pointer">
                <div
                  className={`border rounded-lg p-4 cursor-pointer ${
                    formik.values.travelers[index]?.assets === "yes"
                      ? "border-blue-500"
                      : ""
                  }`}
                >
                  Yes
                  <input
                    type="radio"
                    className="hidden"
                    name={`travelers[${index}].assets`}
                    value="yes"
                    checked={formik.values.travelers[index]?.assets === "yes"}
                    onChange={(e) =>
                      handleTravelerChange(index, "assets", e.target.value)
                    }
                  />
                </div>
              </label>
              <label className="flex items-center cursor-pointer ml-4">
                <div
                  className={`border rounded-lg p-4 cursor-pointer ${
                    formik.values.travelers[index]?.assets === "no"
                      ? "border-blue-500"
                      : ""
                  }`}
                >
                  No
                  <input
                    type="radio"
                    className="hidden"
                    name={`travelers[${index}].assets`}
                    value="no"
                    checked={formik.values.travelers[index]?.assets === "no"}
                    onChange={(e) =>
                      handleTravelerChange(index, "assets", e.target.value)
                    }
                  />
                </div>
              </label>
            </div>
            {formik.touched.travelers &&
              formik.touched.travelers[index]?.assets &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.assets && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.travelers[index].assets}
                </p>
              )}
          </div>

          {/* Have you travelled to other countries before? */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Have you traveled to other countries before?
            </label>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0">
              <label className="flex items-center cursor-pointer md:w-1/2">
                <div
                  className={`border rounded-lg p-4 cursor-pointer flex-1 ${
                    formik.values.travelers[index]?.travelHistory === "no"
                      ? "border-blue-500"
                      : ""
                  }`}
                >
                  No, I haven't left my home country
                  <input
                    type="radio"
                    className="hidden"
                    name={`travelers[${index}].travelHistory`}
                    value="no"
                    checked={
                      formik.values.travelers[index]?.travelHistory === "no"
                    }
                    onChange={(e) =>
                      handleTravelerChange(
                        index,
                        "travelHistory",
                        e.target.value
                      )
                    }
                  />
                </div>
              </label>
              <label className="flex items-center cursor-pointer md:w-1/2 md:ml-4">
                <div
                  className={`border rounded-lg p-4 cursor-pointer flex-1 ${
                    formik.values.travelers[index]?.travelHistory ===
                    "last5years"
                      ? "border-blue-500"
                      : ""
                  }`}
                >
                  Yes, I have traveled in the last 5 years
                  <input
                    type="radio"
                    className="hidden"
                    name={`travelers[${index}].travelHistory`}
                    value="last5years"
                    checked={
                      formik.values.travelers[index]?.travelHistory ===
                      "last5years"
                    }
                    onChange={(e) =>
                      handleTravelerChange(
                        index,
                        "travelHistory",
                        e.target.value
                      )
                    }
                  />
                </div>
              </label>
              {/* Additional options can be added here with similar structure */}
            </div>
            {formik.touched.travelers &&
              formik.touched.travelers[index]?.travelHistory &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.travelHistory && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.travelers[index].travelHistory}
                </p>
              )}
          </div>

          {/* Have you applied for this visa before? */}
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2">
              Have you applied for this visa before?
            </label>
            <div className="flex">
              <label className="flex items-center cursor-pointer">
                <div
                  className={`border rounded-lg p-4 cursor-pointer ${
                    formik.values.travelers[index]?.visaApplication === "yes"
                      ? "border-blue-500"
                      : ""
                  }`}
                >
                  Yes
                  <input
                    type="radio"
                    className="hidden"
                    name={`travelers[${index}].visaApplication`}
                    value="yes"
                    checked={
                      formik.values.travelers[index]?.visaApplication === "yes"
                    }
                    onChange={(e) =>
                      handleTravelerChange(
                        index,
                        "visaApplication",
                        e.target.value
                      )
                    }
                  />
                </div>
              </label>
              <label className="flex items-center cursor-pointer ml-4">
                <div
                  className={`border rounded-lg p-4 cursor-pointer ${
                    formik.values.travelers[index]?.visaApplication === "no"
                      ? "border-blue-500"
                      : ""
                  }`}
                >
                  No
                  <input
                    type="radio"
                    className="hidden"
                    name={`travelers[${index}].visaApplication`}
                    value="no"
                    checked={
                      formik.values.travelers[index]?.visaApplication === "no"
                    }
                    onChange={(e) =>
                      handleTravelerChange(
                        index,
                        "visaApplication",
                        e.target.value
                      )
                    }
                  />
                </div>
              </label>
            </div>
            {formik.touched.travelers &&
              formik.touched.travelers[index]?.visaApplication &&
              formik.errors.travelers &&
              formik.errors.travelers[index]?.visaApplication && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.travelers[index].visaApplication}
                </p>
              )}
          </div>
        </div>
      ))}
    </>
  );
};

export default Step4;
