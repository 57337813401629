import React from 'react';

const Step8 = ({ formik }) => {
  // Handler to manage file uploads
  const handleFileChange = (event, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue(fieldName, file);
    }
  };

  // Handler to remove the uploaded file
  const handleRemoveFile = (fieldName) => {
    formik.setFieldValue(fieldName, null);
  };

  // Drag-and-drop handlers
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event, fieldName) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0]; // Get the first file
    if (file) {
      formik.setFieldValue(fieldName, file); // Set the file in formik state
    }
  };

  return (
    <>
      <div className="md:flex md:flex-row flex-col justify-between border-b border-gray-200">
        <h1 className="text-2xl font-bold pb-4 text-gray-600">
          Upload your Documents
        </h1>
      </div>

      {/* CNIC Upload */}
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900">
          CNIC Upload
        </label>
        <div
          className="flex items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, 'cnic')}
        >
          <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray-500">SVG, PNG, JPG, or GIF</p>
            </div>
            <input
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              onChange={(event) => handleFileChange(event, 'cnic')}
              onBlur={formik.handleBlur}
              className="hidden"
            />
          </label>
        </div>
        {formik.values.cnic && (
          <div className="mt-2">
            <span className="text-gray-600">Uploaded: {formik.values.cnic.name}</span>
            <button
              type="button"
              onClick={() => handleRemoveFile('cnic')}
              className="ml-4 text-red-500 text-sm underline"
            >
              Remove
            </button>
          </div>
        )}
        {formik.errors.cnic && formik.touched.cnic && (
          <span className="text-red-500 text-sm mt-1">{formik.errors.cnic}</span>
        )}
      </div>

      {/* Resume Upload */}
      <div className="mb-4">
        <label className="block text-gray-600 text-sm font-medium mb-2">
          Resume Upload
        </label>
        <div
          className="flex items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, 'resume')}
        >
          <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray-500">PDF, DOC, DOCX</p>
            </div>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={(event) => handleFileChange(event, 'resume')}
              onBlur={formik.handleBlur}
              className="hidden"
            />
          </label>
        </div>
        {formik.values.resume && (
          <div className="mt-2">
            <span className="text-gray-600">Uploaded: {formik.values.resume.name}</span>
            <button
              type="button"
              onClick={() => handleRemoveFile('resume')}
              className="ml-4 text-red-500 text-sm underline"
            >
              Remove
            </button>
          </div>
        )}
        {formik.errors.resume && formik.touched.resume && (
          <span className="text-red-500 text-sm mt-1">{formik.errors.resume}</span>
        )}
      </div>

      {/* Passport Upload */}
      <div className="mb-4">
        <label className="block text-gray-600 text-sm font-medium mb-2">
          Passport Upload
        </label>
        <div
          className="flex items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, 'passport')}
        >
          <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray-500">PDF, JPG, JPEG, PNG</p>
            </div>
            <input
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              onChange={(event) => handleFileChange(event, 'passport')}
              onBlur={formik.handleBlur}
              className="hidden"
            />
          </label>
        </div>
        {formik.values.passport && (
          <div className="mt-2">
            <span className="text-gray-600">Uploaded: {formik.values.passport.name}</span>
            <button
              type="button"
              onClick={() => handleRemoveFile('passport')}
              className="ml-4 text-red-500 text-sm underline"
            >
              Remove
            </button>
          </div>
        )}
        {formik.errors.passport && formik.touched.passport && (
          <span className="text-red-500 text-sm mt-1">{formik.errors.passport}</span>
        )}
      </div>
    </>
  );
};

export default Step8;
