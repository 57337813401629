import React from 'react';
import { FaTrash } from 'react-icons/fa';  

const Step5 = ({ formik, handleTravelerChange, removeTraveler }) => {
  return (
    <>
                  {formik.values.travelers.map((traveler, index) => (
                    <div key={index}>
                      <div className="md:flex md:flex-row flex-col justify-between border-b border-gray-200">
                         <h1 className="text-2xl font-bold pb-4 text-gray-600">
                          {traveler.fname} - Appointment Selection
                        </h1>

                        <button
                          type="button"
                          onClick={() => removeTraveler(index)}
                        >
                          <FaTrash className="h-8 w-8 text-black mb-2 md:md-0" />
                        </button>
                      </div>

                      {/* Have you travelled to other countries before? */}
                      <div className="mb-4">
                        <label className="block text-gray-600 text-sm font-medium mb-2">
                          You'll need to attend an appointment. Please enter
                          your address to find the nearest appointment location.
                        </label>
                        <div className="flex flex-col ">
                          <label className="flex  items-center cursor-pointer mb-2">
                            <div
                              className={`border-2 hover:border-2 hover:border-black text-center font-semibold  rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
                                formik.values.travelers[index]?.appointment ===
                                "Islamabad"
                                  ? "border-2 border-black"
                                  : ""
                              }`}
                            >
                              Islamabad,Pakistan
                              <input
                                type="radio"
                                className="hidden"
                                name={`travelers[${index}].appointment`}
                                value="Islamabad"
                                checked={
                                  formik.values.travelers[index]
                                    ?.appointment === "islamabad"
                                }
                                onChange={(e) =>
                                  handleTravelerChange(
                                    index,
                                    "appointment",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </label>
                          <label className="flex items-center cursor-pointer mb-2">
                            <div
                              className={`border-2 hover:border-2 hover:border-black text-center font-semibold  rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
                                formik.values.travelers[index]?.appointment ===
                                "Lahore"
                                  ? "border-2 border-black"
                                  : ""
                              }`}
                            >
                              Lahore,Pakistan
                              <input
                                type="radio"
                                className="hidden"
                                name={`travelers[${index}].appointment`}
                                value="Lahore"
                                checked={
                                  formik.values.travelers[index]
                                    ?.appointment === "Lahore"
                                }
                                onChange={(e) =>
                                  handleTravelerChange(
                                    index,
                                    "appointment",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </label>
                          <label className="flex items-center cursor-pointer mb-2">
                            <div
                              className={`border-2 hover:border-2 hover:border-black text-center font-semibold  rounded-xl p-4 cursor-pointer w-full md:w-1/2 ${
                                formik.values.travelers[index]?.appointment ===
                                "Karachi"
                                  ? "border-2 border-black"
                                  : ""
                              }`}
                            >
                              Karachi,Pakistan
                              <input
                                type="radio"
                                className="hidden"
                                name={`travelers[${index}].appointment`}
                                value="Karachi"
                                checked={
                                  formik.values.travelers[index]
                                    ?.appointment === "Karachi"
                                }
                                onChange={(e) =>
                                  handleTravelerChange(
                                    index,
                                    "appointment",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </label>
                          {/* Add more options for travel history if needed */}
                        </div>
                        {formik.touched.travelers &&
                          formik.touched.travelers[index]?.appointment &&
                          formik.errors.travelers &&
                          formik.errors.travelers[index]?.appointment && (
                            <p className="text-red-500 text-sm mt-1">
                              {formik.errors.travelers[index].appointment}
                            </p>
                          )}
                      </div>
                      <div className="w-full md:w-3/4 bg-gray-50 border border-gray-400 rounded-xl text-sm p-4 flex items-start mb-2">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="w-full">
                          <span>
                            We estimate that applicants who select this location
                            get their visa in:
                          </span>
                          <p className="font-bold">Less Than 1 Month</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
  )
}

export default Step5