import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useHeroSectionStore from "../../Stores/frontend/landingpage/heroStore"; // Adjust the import path as needed

// Skeleton Loader Component

// Main HeroSection Component
const HeroSection = () => {
  const navigate = useNavigate();
  const { fetchHeroSection, heroSection, isFetchingHeroSection, error } =
    useHeroSectionStore();

  useEffect(() => {
    const getHeroSection = async () => {
      try {
        await fetchHeroSection();
      } catch (fetchError) {
        console.error("Error fetching hero section:", fetchError);
        alert(`Error fetching hero section: ${fetchError.message}`);
      }
    };

    getHeroSection();
  }, [fetchHeroSection]);

  // Handle error display
  useEffect(() => {
    if (error) {
      console.error("Error fetching hero section:", error);
      alert(`Error fetching hero section: ${error}`); // You might want to use a toast or some other UI feedback
    }
  }, [error]);

  const splitHeading = (heading) => {
    const words = heading.split(" ");
    if (words.length <= 2) {
      return { firstPart: heading, secondPart: "" };
    }
    const firstPart = words.slice(0, 2).join(" ");
    const secondPart = words.slice(2).join(" ");
    return { firstPart, secondPart };
  };

  return (
    <>
      <div className="bg-white relative pb-20">
        <div className="relative xl:container m-auto px-6 md:px-12 lg:px-6">
          <h1
            className={`${
              isFetchingHeroSection
                ? "animate-pulse bg-gray-300 rounded h-10"
                : ""
            } sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-blue-900 text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-7xl`}
          >
            {isFetchingHeroSection ? (
              
              ""
            ) : heroSection?.heading ? (
              <>
                {(() => {
                  const { firstPart, secondPart } = splitHeading(
                    heroSection.heading
                  );
                  return (
                    <>
                      {firstPart} <br className="lg:block hidden" />
                      <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-500">
                        {secondPart}
                      </span>
                    </>
                  );
                })()}
              </>
            ) : (
              <>
                Get your <br className="lg:block hidden" />
                <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-500">
                  Global Visa
                </span>
                .
              </>
            )}
          </h1>

          <div className="lg:flex">
            <div className="relative mt-8 md:mt-16 space-y-8 sm:w-10/12 md:w-2/3 lg:ml-0 sm:mx-auto text-center lg:text-left lg:mr-auto lg:w-7/12">
              <p
                className={`${
                  isFetchingHeroSection
                    ? "animate-pulse bg-gray-300 rounded h-6"
                    : "sm:text-lg text-gray-700 lg:w-11/12"
                }`}
              >
                {isFetchingHeroSection
                  ? ""
                  : heroSection?.mainText || "Streamline your global mobility with our comprehensive visa services: expert guidance, personalized support, and seamless application processing for all your travel and immigration needs."}
              </p>
              <span
                className={`${
                  isFetchingHeroSection
                    ? "animate-pulse bg-gray-300 rounded h-4"
                    : "block font-semibold text-gray-500"
                }`}
              >
                {isFetchingHeroSection ? (
                  
                  <div className="h-4 bg-gray-300 rounded animate-pulse mt-10"></div>
                ) : (
                  heroSection?.supportingText || "Find the perfect visa solution for your needs. Start your journey now"
                )}
              </span>

              <div className="grid grid-cols-3 space-x-4 md:space-x-6 md:flex md:justify-center lg:justify-start">
                <button
                  onClick={() => navigate("/apply")}
                  aria-label="apply now"
                  className="p-4 border border-gray-200 rounded-full duration-300 hover:border-cyan-400 hover:shadow-lg hover:shadow-cyan-600/20"
                >
                  <div className="flex justify-center space-x-4">
                    <img
                      className="w-6 h-6"
                      src="https://tailus.io/sources/blocks/tech-startup/preview/images/slack.png"
                      alt="slack logo"
                      loading="lazy"
                      width="128"
                      height="128"
                    />
                    <span className="hidden font-medium md:block ">
                      Apply Now
                    </span>
                  </div>
                </button>
                <a
                  aria-label="add to chat"
                  href="/"
                  className="p-4 border border-gray-200 rounded-full duration-300 hover:border-green-400 hover:shadow-lg hover:shadow-lime-600/20"
                >
                  <div className="flex justify-center space-x-4">
                    <img
                      className="w-6 h-6"
                      src="https://tailus.io/sources/blocks/tech-startup/preview/images/chat.png"
                      alt="chat logo"
                      loading="lazy"
                      width="128"
                      height="128"
                    />
                    <span className="hidden font-medium md:block ">
                      Google Chat
                    </span>
                  </div>
                </a>
              </div>
            </div>

            <div className="mt-10 md:mt-0 lg:absolute right-8 lg:w-4/12">
              <div className="relative w-full">
                <div
                  aria-hidden="true"
                  className="absolute scale-75 md:scale-110 inset-0 m-auto w-full h-full md:w-96 md:h-96 rounded-full rotate-45 bg-gradient-to-r from-sky-500 to-cyan-300 blur-3xl"
                ></div>
                <img
                  src="https://tailus.io/sources/blocks/tech-startup/preview/images/globalization-cuate.svg"
                  className="relative w-full"
                  alt="illustration"
                  loading="lazy"
                  width="320"
                  height="280"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
