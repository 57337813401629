import React, {useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { countryData } from './countryData';
import Footer from '../../Footer/Footer';
import Feature from './Feature';
import Form from './Form';
import HowToApply from './HowToApply';
import FAQs from './FAQs';
import Blogs from './Blogs';
import WhyUs from './WhyUs';
import WhyThis from './WhyThis';
import Testimonials from './Testimonials';
import Navbar from '../../Navbar/Navbar';
import Reviews from './Reviews';


const CountiresDetail = () => {
    const { countryCode } = useParams(); // Extract the country code from the URL
  const country = countryData[countryCode];
  const naviagte = useNavigate();
   // Get the country data based on the code
  useEffect(() => {
    // Function to scroll to the top
    const goToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    goToTop();
  }, []);
  // Check if country data exists
  if (!country) {
    return <p>Country data not found.</p>;
  }
  return (
    <>
    <Navbar />

<div className="flex-col md:flex md:flex-col lg:flex-row lg:justify-between h-screen w-full  ">
        <div className="flex lg:w-1/2 w-full p-5 flex-col md:mt-20">
          <div className="flex text-lg text-6xl lg:text-5xl mb-4 font-bold">
          <h1 className="sm:w-10/12 md:w-2/3 font-black text-blue-900 text-4xl sm:text-5xl md:text-6xl lg:w-auto xl:text-7xl ">
             Apply for {" "}
              <br className="lg:block hidden" />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-500">
                {country.name}
              </span>
              .
            </h1>
          </div>
          <p className="text-gray-700 text-sm sm:text-base p-4">
    Looking to explore {country.name}? We make the visit visa application process easy and straightforward. Whether you're planning a short vacation, a business trip, or visiting family, our expert services ensure a smooth and hassle-free experience. With clear guidance on the required documents, fast processing times, and personalized support, you'll be on your way to {country.name} in no time. Start your application today and get ready to experience the beauty and culture of {country.name}.
  </p>
          <div className="flex gap-x-1">
            <button
             onClick={()=> naviagte('/apply')}
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-gradient-to-r from-cyan-600 to-cyan-800 hover:from-cyan-700 hover:to-cyan-900 focus:shadow-outline focus:outline-none"
            >
             Apply Now
            </button>
            <a
              href="/"
              aria-label=""
              className="inline-flex hover:text-white hover:bg-black rounded items-center border border-2 border-black border-solid font-semibold h-12 px-6 mr-6"
            >
              Book a Meeting
            </a>
          </div>
          
        </div>

        <div className="lg:flex h-2/4 md:h-3/4 md:w-1/2 lg:h-screen lg:w-1/2 lg:p-8 lg:flex-col flex-col flex p-6 ">
          

        <div className="relative h-full rounded-lg">
          <img
                
                src={country.image}
                alt={country.name}
                className={`imgty absolute top-0 left-0 w-full h-full rounded-lg transition-opacity duration-500 `}
              
              />
          </div>
        </div>
      </div>
  
    
    <Form />
  {/* <div className="p-4 max-w-2xl mx-auto pt-20">
     
      <h1 className="text-3xl font-bold mb-4">{country.name}</h1>

      
      <img
        src={country.image}
        alt={`${country.name}`}
        className="w-full h-64 object-cover rounded-lg mb-4"
      />

      
      <div className="mb-4">
        <p className="text-gray-700 mb-2">{country.description.part1}</p>
        <p className="text-blue-700 font-semibold mb-2">{country.description.part2}</p>
        <p className="text-gray-500 italic">{country.description.part3}</p>
      </div>

      
      <h2 className="text-2xl font-semibold mb-2">Why Visit {country.name}?</h2>
      <ul className="list-disc pl-5 mb-4">
        {country.reasonsToVisit.map((reason, index) => (
          <li key={index} className="text-gray-600">
            {reason}
          </li>
        ))}
      </ul>

     
      <h2 className="text-2xl font-semibold mb-2">Top Touring Spots</h2>
      <div className="space-y-4">
        {country.touringSpots.map((spot, index) => (
          <div key={index} className="border p-3 rounded-lg shadow-md">
            <h3 className="text-xl font-bold">{spot.name}</h3>
            <p className="text-gray-600">{spot.description}</p>
          </div>
        ))}
      </div>
    </div> */}
  <Feature />
  {/* <WhyThis /> */}
  <HowToApply />
  <WhyUs />
  <Reviews />
  <Testimonials />
  <FAQs />
  <Blogs />
    <Footer />
    </>
  )
}

export default CountiresDetail